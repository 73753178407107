import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { onUpdateUser } from '../../Redux/Actions/AuthActions';

const AccountInfo = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState({
        user: false
    });
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, user: true })
        if (user.name === auth.user.name) {
            setLoader({ ...loader, user: false });
            Swal.fire({
                icon: 'info',
                title: 'No Changes Detected',
                text: 'Your name is the same as the current one. No updates were made.',
                confirmButtonColor: "#000"
            });
            return;
        }
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }


    useEffect(() => {
        if (auth.user) {
            setUser({ name: auth.user.name, email: auth.user.email })
        }
    }, [auth])

    return (
        <div className="profileBlock">
            <h2 className="text-center">Personal Information</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Stephanie"
                                onChange={(e) => handleChange(e)}
                                required
                                name="name"
                                value={user.name}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted">Your Email</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="example@gmail.com"
                                value={user.email}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                <button type="submit" className="fbtn btn-block demoLink"> {loader.user ? <>Updating <i className="fa fa-spinner fa-spin mr-2" /></> : 'Update'}  </button>
            </form>
        </div>
    )
}

export default AccountInfo