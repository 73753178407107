import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateQuiz = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/create-quiz?id=${res.data}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchQuiz = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-quiz", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_QUIZ_DATA", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onNextQuiz = (data, handleNextTab, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-quiz", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data) {
                    dispatch({ type: "ADD_QUIZ_FINAL_DATA", payload: res.data })
                }
                handleNextTab()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

//No Axios Here

export const onChangeQuizSource = (data) => (dispatch) => {
    dispatch({ type: "CHANGE_SOURCE", payload: data })
}

export const onChangeManualPrompt = (text) => (dispatch) => {
    dispatch({ type: "ONCHANGE_MANUAL_PROMPT", payload: text })
}

export const onHandleCourseChange = (checked, courseId, chapters) => (dispatch) => {
    dispatch({ type: "ONCHANGE_COURSE_QUIZ", payload: { checked, courseId, chapters } })
}

export const onHandleChapterChange = (checked, chapterId) => (dispatch) => {
    dispatch({ type: "ONCHANGE_CHAPTER_QUIZ", payload: { checked, chapterId } })
}

export const onChangeAnswerType = (val) => (dispatch) => {
    dispatch({ type: "ONCHANGE_ANSWERETYPE_QUIZ", payload: val })
}
export const onChangeQuestionType = (name, checked) => (dispatch) => {
    dispatch({ type: "ONCHANGE_QUESTIONTYPE_QUIZ", payload: { name, checked } })
}

export const onChangeCustomization = (name, value) => (dispatch) => {
    dispatch({ type: "ONCHANGE_CUSTOMIZATION_QUIZ", payload: { name, value } })
}

export const onChangeCustomizationParticipant = (name, value) => (dispatch) => {
    dispatch({ type: "ONCHANGE_CUSTOMIZATION_PARTICIPANT_QUIZ", payload: { name, value } })
}


export const onChangeContentCreated = (name) => (dispatch) => {
    dispatch({ type: "CLEAN_PAST_CONTENT", payload: name })
}

// export const onChangeQuestionText = (text, activeIndex) => (dispatch) => {
//     dispatch({ type: "CHANGE_QUESTION_TEXT", payload: { text, activeIndex } })
// }

// export const onChangeOptionText = (text, activeIndex, optionIndex) => (dispatch) => {
//     dispatch({ type: "CHANGE_OPTION_TEXT", payload: { text, activeIndex, optionIndex } })
// }

export const onReorderQuestions = (questionArr, activeIndex) => (dispatch) => {
    dispatch({ type: "ON_REORDER_QUESTION", payload: { questionArr, activeIndex } })
}

export const onReorderOptions = (optionArr, activeIndex) => (dispatch) => {
    dispatch({ type: "ON_REORDER_OPTIONS", payload: { optionArr, activeIndex } })
}

export const onDeleteQuestion = (index) => (dispatch) => {
    dispatch({ type: "ON_DELETE_QUESTION", payload: index })
}

export const onAddQuestion = (data) => (dispatch) => {
    dispatch({ type: "ON_ADD_QUESTION", payload: data })
}
