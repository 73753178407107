import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addAccData } from '../../Redux/Actions/AccountManagements';

const CreateAccount = ({ fetchMembers }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false,
    });
    const [user, setUser] = useState({
        type: 'team_member',
        email: "",
        name: "",
        password: "",
    });


    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false,
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (user.password) {
            if (pattern.test(user.password)) {
                setLoader({
                    ...loader,
                    create: true,
                });
                dispatch(addAccData(user, fetchMembers, loader, setLoader, setUser));
                setPasswordMsg({ ...passwordMsg, validation: false, msg: "" });
            } else {
                setPasswordMsg({
                    ...passwordMsg,
                    msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.",
                    validation: true,
                });
            }
        }
    };

    return (
        <div className="profileBlock-wrap">
            <h2 className="infoTitle">Create Account</h2>
            <div className="profileBlock mt-0">

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-group-alt mt-0">
                                <label className="form-text text-muted">Account Type</label>
                                <select className="form-control" name="type" onChange={handleChange}>
                                    <option style={{ backgroundColor: "#000" }} value="team_member">Team Member</option>
                                    <option style={{ backgroundColor: "#000" }} value="client">Client</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt mt-4 mt-md-0">
                                <label className="form-text text-muted">Account Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Account Name"
                                    required
                                    name="name"
                                    value={user.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Account Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    required
                                    name="email"
                                    value={user.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Account Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="********"
                                    required
                                    name="password"
                                    value={user.password}
                                    onChange={handleChange}
                                />
                                {passwordMsg.validation ? <small className="text-danger">{passwordMsg.msg}</small> : ""}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="fbtn btn-block demoLink" disabled={loader.create}>
                            {loader.create ? <>Creating  <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Create'}
                        </button>
                    </div>

                </form>

            </div>


        </div>
    )
}

export default CreateAccount