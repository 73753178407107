import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../Global/Global';
import CoursesListRow from './CoursesListRow';
import { onChangeManualPrompt } from '../../../Redux/Actions/QuizActions';

const ProvideInput = () => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)

  const [courses, setCourses] = useState([])
  const [loader, setLoader] = useState({
    fetch: true
  })

  const handleChange = (e) => {
    dispatch(onChangeManualPrompt(e.target.value))
  }

  const fetchQuizCahpters = () => {
    let data = {}
    dispatch(fetchData("fetch-quiz-courses", data, setCourses, loader, setLoader))
  }

  useEffect(() => {
    fetchQuizCahpters()
  }, [])

  return (
    <>
      {quiz.quizSource === "course" ?
        <div className='quiz-card'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className='gradient-box'>
                <div className='gradient-box-head'>
                  <p style={{ color: '#FFF' }}>All Your Slides</p>
                  <p style={{ fontSize: '13px' }}>Select Chapters you would like AI to Generate Quiz Questions from </p>
                </div>
                <div className='gradient-box-in'>
                  <div className='slide-selection'>
                    <div className='slide-selection-group'>
                      {courses.length > 0 ?
                        courses.map((curElem, index) => {
                          return (
                            <React.Fragment key={index}>
                              <CoursesListRow
                                curElem={curElem}
                                index={index}
                              />
                            </React.Fragment>
                          )
                        })
                        :
                        <div className='d-flex align-items-center justify-content-between'>
                          {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: themeColor }} /> : "No chapter available in any of the courses."}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        quiz.quizSource === "manual" ?
          <>
            <h2 className='pb-3'>Manual Prompt</h2>
            <div className='quiz-card'>
              <div className='row justify-content-center'>
                <div className='col-lg-8 text-center'>
                  <h5 className='gradientTxt'><span>Type in Specific Topic or Command  for you Quiz</span></h5>
                  <p style={{ fontSize: '0.9rem' }} className='pt-2'>👇 Type in a specific command and let our AI Generate a Quiz for you 👇</p>
                  <div className='mycourseInp-wrap for-input full'>
                    <input
                      className='courseInp mycourseInp'
                      type="text"
                      placeholder='Generate a quiz on Newtons Laws of Motion.'
                      value={quiz.prompt}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </> : ""
      }
    </>
  )
}

export default ProvideInput