import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { changeProfile } from '../../Redux/Actions/AuthActions';
import { FiEdit } from 'react-icons/fi';

const AccountImage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState({
        image: false
    })

    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({ ...loader, image: true })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#000"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected an invalid file type!',
                confirmButtonColor: "#000"
            })
        }
    }
    return (
        <div className="proImage-wrap">
            <div className="proImage">
                <div className="proImage-holder">
                    <img src={auth.user.profile} alt="user-img" width="100px" height="100px" />
                    <div className={`${loader.image === false ? 'hide-social' : ''} my-account-loader`}>
                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                    </div>
                    <div className="custom-file">
                        <FiEdit />
                        <input type="file" onChange={(e) => onImageChange(e)}
                            className="custom-file-input" accept="image/*"
                        />
                    </div>
                </div>
                <h5>{auth.user ? auth.user.name : null}</h5>
                <button className="demoLink-2 mt-2 text-capitalize">
                    {
                        auth.user ?
                            auth.user.membership.split("__")[(auth.user.membership.split("__").length - 1)]
                            : null
                    }
                </button>
            </div>
        </div>
    )
}

export default AccountImage