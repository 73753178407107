import { produce } from "immer"

const initialState = {
    data: false
}

export const QuizReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_QUIZ_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }

                if (!obj.courseContent) {
                    obj.courseContent = {
                        courseId: false,
                        chapters: []
                    }
                }
                if (!obj.questionType) {
                    obj.questionType = {
                        answereChoice: "single",
                        questionChoice: {
                            trueFalse: false,
                            shortAnswerQuestion: false,
                            fillInTheBlank: false,
                            matchingQuestions: false,
                            imageBasedQuestions: false,
                            scenarioBasedQuestion: false
                        }
                    }
                }
                if (!obj.quizCustomization) {
                    obj.quizCustomization = {
                        difficultyLevel: "easy",
                        noOfQuestion: 50,
                        timeLimit: 60,
                        participantDetails: {
                            firstName: true,
                            lastName: true,
                            email: true,
                        }
                    }
                }

                if (!obj.quizData) {
                    obj.quizData = []
                }
                draft.data = obj
            })

        case "CHANGE_SOURCE":
            return produce(state, (draft) => {
                draft.data.quizSource = action.payload
            })

        case "ONCHANGE_MANUAL_PROMPT":
            return produce(state, (draft) => {
                draft.data.prompt = action.payload
            })

        case "ONCHANGE_COURSE_QUIZ":
            return produce(state, (draft) => {
                if (action.payload.checked) {
                    draft.data.courseContent.courseId = action.payload.courseId
                    draft.data.courseContent.chapters = action.payload.chapters
                } else {
                    draft.data.courseContent.courseId = false
                    draft.data.courseContent.chapters = []
                }
            })

        case "ONCHANGE_CHAPTER_QUIZ":
            return produce(state, (draft) => {
                if (action.payload.checked) {
                    draft.data.courseContent.chapters.push(action.payload.chapterId)
                } else {
                    let selectedIndex = draft.data.courseContent.chapters.findIndex(({ id }) => +id === +action.payload.chapterId)
                    if (selectedIndex !== -1) {
                        draft.data.courseContent.chapters.splice(selectedIndex, 1)
                        if (draft.data.courseContent.chapters.length === 0) {
                            draft.data.courseContent.courseId = false
                        }
                    }
                }
            })


        case "ONCHANGE_ANSWERETYPE_QUIZ":
            return produce(state, (draft) => {
                draft.data.questionType.answereChoice = action.payload
            })

        case "ONCHANGE_QUESTIONTYPE_QUIZ":
            return produce(state, (draft) => {
                draft.data.questionType.questionChoice = {
                    ...draft.data.questionType.questionChoice,
                    [action.payload.name]: action.payload.checked
                }
            })

        case "ONCHANGE_CUSTOMIZATION_QUIZ":
            return produce(state, (draft) => {
                draft.data.quizCustomization = {
                    ...draft.data.quizCustomization,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ONCHANGE_CUSTOMIZATION_PARTICIPANT_QUIZ":
            return produce(state, (draft) => {
                draft.data.quizCustomization.participantDetails = {
                    ...draft.data.quizCustomization.participantDetails,
                    [action.payload.name]: action.payload.value
                }
            })

        case "CLEAN_PAST_CONTENT":
            return produce(state, (draft) => {
                if (action.payload === "courseContent") {
                    draft.data.courseContent = {
                        courseId: false,
                        chapters: []
                    }
                } else {
                    draft.data.prompt = ""
                }
            })

        case "ADD_QUIZ_FINAL_DATA":
            return produce(state, (draft) => {
                draft.data.quizData = action.payload
            })

        // case "CHANGE_QUESTION_TEXT":
        //     return produce(state, (draft) => {
        //         draft.data.quizData[action.payload.activeIndex].question = action.payload.text
        //     })
        // case "CHANGE_OPTION_TEXT":
        //     return produce(state, (draft) => {
        //         draft.data.quizData[action.payload.activeIndex].options[action.payload.optionIndex] = action.payload.text
        //     })

        case "ON_REORDER_QUESTION":
            return produce(state, (draft) => {
                draft.data.quizData = action.payload.questionArr
            })


        case "ON_REORDER_OPTIONS":
            return produce(state, (draft) => {
                draft.data.quizData[action.payload.activeIndex].options = action.payload.optionArr
            })


        case "ON_DELETE_QUESTION":
            return produce(state, (draft) => {
                draft.data.quizData.splice(action.payload, 1)
            })

        case "ON_ADD_QUESTION":
            return produce(state, (draft) => {
                draft.data.quizData.push(action.payload)
            })

        default:
            return state
    }
}