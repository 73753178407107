import React, { useEffect, useState } from 'react'
import modalCloseIcon from '../../images/modal-close.png';
import { Modal } from 'react-bootstrap';
import { onEditClient } from '../../Redux/Actions/AccountManagements';
import { useDispatch } from 'react-redux';

const EditMemberModal = ({ curElem, show, type, handleClose, fetchMembers }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({
        type: 'team_member',
        email: "",
        name: "",
        password: "",
    });
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedUser = { ...user };
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (user.password && !pattern.test(user.password)) {
            setPasswordMsg({
                msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and be at least 8 characters long.",
                validation: true,
            });
            return;
        }
        setLoader(true);
        dispatch(onEditClient(updatedUser, fetchMembers, handleClose, setLoader));
    };

    useEffect(() => {
        if (curElem) {
            setUser({
                ...user,
                type: type,
                name: curElem.name,
                email: curElem.email,
                id: curElem.id
            })
        }
    }, [curElem])

    return (
        <Modal className="VideoModal  white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Edit Accounts</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-group-alt mt-4 mt-md-0">
                                <label className="form-text text-muted">Account Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={user.type}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt mt-4 mt-md-0">
                                <label className="form-text text-muted">Account Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Account Name"
                                    required
                                    name="name"
                                    value={user.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Account Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    required
                                    name="email"
                                    value={user.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Account Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="********"
                                    name="password"
                                    value={user.password}
                                    onChange={handleChange}
                                />
                                {passwordMsg.validation ? <small className="text-danger">{passwordMsg.msg}</small> : ""}
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        <button type="submit" className="text-end inpBtn mt-3 create-modal-btn">{loader ? <>Updating  <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Update'}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditMemberModal