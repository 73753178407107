import React, { useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';

import { Link } from 'react-router';


const GenerateLandingPage = () => {


    return (
        <>
            <TitleBar title="Generate Landing Page" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Save as Draft</button>
                                        <button className="demoLink">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt mt-4'><span>Choose How You Want To Generate Your Landing Page</span></h2>
                                <h6 className='text-center pt-2'>👇 Select how you want to Generate your Landing Page 👇</h6>

                                <div className='choose-landing mt-5'>
                                    <Link to="/content-extraction">
                                    <div className='landing-single'>
                                        <div className='landing-img'><img src={require('../../images/dashboard/img-1.png')} alt='' /></div>
                                        <div className='landing-info'>
                                            <h5 className='gradientTxt'><span>Content Extraction</span></h5>
                                            <p className='pt-2'>The AI analyzes course data to extract key elements for the landing page.</p>
                                        </div>
                                    </div>
                                    </Link>
                                    <Link to="/pre-filled-landing-page">
                                    <div className='landing-single'>
                                        <div className='landing-img'><img src={require('../../images/dashboard/img-2.png')} alt='' /></div>
                                        <div className='landing-info'>
                                            <h5 className='gradientTxt'><span>Pre-filled Sections</span></h5>
                                            <p className='pt-2'>The Landing Page Builder automatically fills in relevant sections.</p>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <Footer />

        </>
    )
}

export default GenerateLandingPage;