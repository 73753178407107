import React, { useState } from "react";
import youtubeIcon from "../../images/YT-DEFAULT.png"
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { deleteSocialAccounts, saveYoutube } from "../../Redux/Actions/SocialActions";
import { MdDeleteForever } from "react-icons/md";
import CommonAlert from "../CommonComponents/CommonAlert";

const ConnectYoutube = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false,
        icon: <MdDeleteForever />
    })

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteSocialAccounts("delete-social-network", data, setSweet))
    }


    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }

    const connectYoutube = (code) => {
        let data = JSON.stringify({ code: code, user_id: auth.user.id })
        dispatch(saveYoutube(data))
    }

    return (
        <>
            <div className="connection-box mt-3" id="youtube">
                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={youtubeIcon} width="56px" alt="youtube" />
                    </div>
                    <div className="connection-txt">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>Youtube</h6>
                                                    <p className="m-0">{item?.username} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button type="button" onClick={(e) => onDelete(item.id)} className="btn-change7">Disconnect</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <GoogleLogin
                                                    clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                                    render={(renderProps) => (
                                                        <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect New Account</button>
                                                    )}
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}
                                                    scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                                    responseType="code"
                                                    accessType="offline"
                                                    prompt="consent"
                                                    cookiePolicy={"single_host_origin"}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                        <div className="youtube-content">
                                            <h6>Youtube</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <GoogleLogin
                                            clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                            render={(renderProps) => (
                                                <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect</button>
                                            )}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                            responseType="code"
                                            accessType="offline"
                                            prompt="consent"
                                            cookiePolicy={"single_host_origin"}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to disconnect this connection?"}
                confirmButtonColor={""}
                icon={sweet.icon}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default ConnectYoutube;