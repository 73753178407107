import React from 'react'

function VisualStyle() {
  return (
    <>
      <div className='promo-editor-card scroll-1'>
        <h6 className='pb-2'>Visual Style and Theme</h6>
        <div className='visual-style'>
            <ul>
                <li>
                    <div className='visual-style-single'>
                        <div className='visual-style-img'><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        <p>Minimalist</p>
                    </div>
                </li>
                <li>
                    <div className='visual-style-single'>
                        <div className='visual-style-img'><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        <p>Futuristic</p>
                    </div>
                </li>
                <li>
                    <div className='visual-style-single'>
                        <div className='visual-style-img'><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        <p>Playful</p>
                    </div>
                </li>
                <li>
                    <div className='visual-style-single'>
                        <div className='visual-style-img'><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        <p>Minimalist</p>
                    </div>
                </li>
            </ul>
        </div>
      </div>
    </>
  )
}

export default VisualStyle