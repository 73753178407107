import React, { useEffect, useState } from 'react'
import TitleBar from '../../CommonComponents/TitleBar';
import Navbar from '../../CommonComponents/Navs/Navbar';
import SideNav from '../../CommonComponents/Navs/SideNav';
import CourseHeader from './CourseHeader';
import { IoArrowBackSharp } from 'react-icons/io5';
import Footer from '../../CommonComponents/Footer/Footer';
import ChapterCard from '../ChapterCard';
import RecordAudioModal from './RecordAudioModal';
import ModalUpload from './ModalUpload';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { onFetchCourse } from '../../../Redux/Actions/CourseActions';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import FileUploadModal from '../../CommonComponents/FileUploadModal';

const Chapters = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = queryString.parse(location.search)

    const auth = useSelector((state) => state.auth);
    const course = useSelector((state) => state.course.data);

    const [loader, setLoader] = useState({
        fetch: true,
        upload: false
    });

    const [chapterId, setChapterId] = useState("");
    const [uploadData, setUploadData] = useState({
        file: "",
        type: ""
    });
    const [percent, setPercent] = useState(0);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const onChangeChapter = (e) => {
        if (e.target.value === "Edit") {
            navigate(`/create-chapter?id=${id}`);
        } else {
            +auth.user.isClientAccount !== 1 ? setChapterId(e.target.value) : setChapterId("")
        }
    };

    const handleWrite = () => {
        if (chapterId !== "") {
            navigate(`/write-chapters?id=${id}&chapId=${chapterId}`);
        }
    };
    const handleRecording = () => {
        if (chapterId !== "") {
            handleShow3();
        }
    };

    const onCompleteUpload = (url, type) => {
        handleClose4()
        handleShow5()
        setUploadData({
            ...uploadData,
            file: url,
            type: type
        })
    }

    const onInputFile = (e) => {
        if (e.target.files.length > 0) {
            if (['video/mp4', 'audio/mpeg'].includes(e.target.files[0].type)) {
                let type = e.target.files[0].type.includes('audio') ? "music" : "video"
                if (e.target.files[0].size < 20000000) {
                    const formData = new FormData()
                    formData.append('file', e.target.files[0]);
                    formData.append("upload_type", type);
                    handleShow4()
                    dispatch(onUploadMedia(formData, onCompleteUpload, loader, setLoader, setPercent, type))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 20MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected an invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }


    const fetchCourse = () => {
        let data = {
            courseId: id
        }
        dispatch(onFetchCourse(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchCourse()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Chapters" />
                <Navbar />
                <section className="siteWrap">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="create-course-top flex">
                                <div className="course-top-left">
                                    <h2>
                                        <CourseHeader
                                            courseName={course.projectName}
                                            courseId={course.id}
                                            topicName={course.topicName}
                                        // config={config}
                                        />
                                        <span style={{ display: 'inline-block', paddingLeft: 5 }}>| {course.topicName}</span>
                                    </h2>
                                </div>
                                <div className="projectTitle-right">
                                    <button className="demoLink grey m-0" onClick={() => navigate(-1)}><IoArrowBackSharp /> Back</button>
                                    {/* <button className="demoLink m-0">Continue</button> */}
                                </div>
                            </div>

                            <div className="create-course-mid">
                                <div className="container">
                                    <div className="course-mid-main">
                                        <h2 className="text-center gradientTxt"><span>Edit A Chapter</span></h2>
                                        <p className="text-center">👇 Select a Chapter You Would Like To Edit from the Drop Down 👇</p>
                                        <div className="mycourseInp-wrap">
                                            <select
                                                className="courseInp mycourseInp"
                                                onChange={(e) => onChangeChapter(e)}
                                            >
                                                <option value="">Select Chapter</option>
                                                {course.chapters.length > 0 ?
                                                    course.chapters.map((curElem, index) => {
                                                        return (
                                                            <option
                                                                value={curElem.id}
                                                                key={index}
                                                            >
                                                                {curElem.name.length > 50 ? curElem.name.slice(0, 50) + "..." : curElem.name}
                                                            </option>
                                                        );
                                                    })
                                                    : ""}
                                                {+auth.user.isClientAccount !== 1 ? (
                                                    <option
                                                        value={"Edit"}
                                                        className="btn btn-success"
                                                        type="button"
                                                        style={{ fontSize: "20px" }}
                                                    >
                                                        Add / Edit Chapters
                                                    </option>
                                                ) : (
                                                    ""
                                                )}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="course-step">

                                        <div className="Selected-title">Choose what you would like to do with the Selected Chapter Below</div>

                                        <div className="selected-btn">
                                            <div className="selected-btn-single">
                                                <span
                                                    onClick={handleWrite}
                                                    style={chapterId === "" ? { background: "gray" } : {}}
                                                >
                                                    <img alt="" src={require('../../../images/update.svg').default} />
                                                    Create / Write & Update
                                                </span>
                                            </div>
                                            <div className="selected-btn-single">
                                                <span className="purple"
                                                    style={chapterId === "" ? { background: "gray" } : {}}
                                                >
                                                    <img alt="" src={require('../../../images/upload-vid.svg').default} />
                                                    Upload Video/Audio
                                                    <input
                                                        className="absInput"
                                                        type="file"
                                                        accept="audio/*, video/*"
                                                        disabled={chapterId === ""}
                                                        onChange={(e) => onInputFile(e)}
                                                    />
                                                </span>
                                            </div>

                                            <div className="selected-btn-single">
                                                <span className="red"
                                                    onClick={handleRecording}
                                                    style={chapterId === "" ? { background: "gray" } : {}}
                                                >
                                                    <img alt="" src={require('../../../images/micro.svg').default} />
                                                    Record Audio
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid mb-2 pt-4">
                                <div className="row">
                                    {course.chapters.length > 0 ?
                                        course.chapters.map((curElem, index) => {
                                            return (
                                                <div className="col-xl-3 col-md-6 col-12" key={index}>
                                                    <ChapterCard
                                                        curElem={curElem}
                                                        fetchCourse={fetchCourse}
                                                    />
                                                </div>
                                            );
                                        })
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <RecordAudioModal
                    show3={show3}
                    handleClose3={handleClose3}
                    onInputFile={onInputFile}
                />

                <FileUploadModal
                    show={show4}
                    handleClose={handleClose4}
                    percent={percent}
                />

                <ModalUpload
                    handleClose5={handleClose5}
                    show5={show5}
                    uploadData={uploadData}
                    chapterId={chapterId}
                />

                <Footer />
            </>
    )
}

export default Chapters