

import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { baseURL } from '../../../global/global';
import { useDispatch, useSelector } from 'react-redux';
// import { setIntroStatus, removeIntro, updateIntroOutro } from '../../../actions/chapterAction';
// import { setAlert } from '../../../actions/alert';
import closeModalx from "../../../images/modal-close.png"
// import swal from 'sweetalert';
import { Tab, Nav, Modal } from "react-bootstrap";
import sample from "../../../images/sample.png";
import HoverVideoPlayer from "react-hover-video-player";
import { FiTrash2 } from 'react-icons/fi';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { useHistory } from 'react-router-dom';
import Outro from './Outro';
import { useNavigate } from 'react-router';
import IntroOutro from './IntroOutro';
// import { commonAxios } from "../../../global/CommonAxios"
// import { updateCourseChapIntroOutro } from '../../../actions/courseAction';


const Credit = () => {

  const campaign = useSelector(state => state.video.data)
  const intro = campaign.intro
  const outro = campaign.outro


  return (
    <>

      <IntroOutro
        data={intro}
        type="intro"
      />
      <hr />
      <IntroOutro
        data={outro}
        type="outro"
      />

      {/* <div className="tabInner">
        <div className="media-block">
          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title">
                <h2>Intro</h2>
                <div className="switch-single">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={introData.enable}
                      onChange={(e) => handleStatusIntroOutro(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {!introData.enable ?
                <>
                  <div className="fileUpload mt-4" style={introData.src ? { color: '#e86be8' } : {}}>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    {
                      introData.name ?
                        introData.name.length > 23 ? `${introData.name.slice(0, 23)}...` : introData.name : "Upload Your Intro"}
                    <input
                      type="file"
                      className='cursor-pointer'
                      onChange={(e) => onInputVideo(e, 'intro')}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {loader.introLoader ? <i className="fa fa-spinner fa-spin loader-center" />
                            : <img
                              src={introData.thumbnail ? introData.thumbnail : sample}
                              alt=""
                              className="uploadImg"
                            />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className='itemDisp-right'>
                      <button onClick={handleShow}>Select Intro video +</button>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                      <button className='model-close-button text-right' onClick={handleClose}>
                        <img src={closeModalx} alt="" />
                      </button>
                      <Modal.Body style={{ color: '#ffffff' }}>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="tabInner">
                              <Tab.Container id="" defaultActiveKey="intro-video">
                                <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                  <Nav.Item>
                                    <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded Intro videos</Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link eventKey="Videoreel-video" style={{ fontSize: '15px' }}>Videoreel Intro videos</Nav.Link>
                                  </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                  <Tab.Pane eventKey="intro-video">
                                    <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                      <div className="row">
                                        {introArr.length > 0 ?
                                          introArr.map((curElem) => {
                                            return (
                                              <div
                                                className="col-md-4 col-6"
                                                key={curElem.id}
                                                onClick={() => selectIntro(curElem, "intro")}
                                              >
                                                <div className="credit-video-card" style={{
                                                  border: "none",
                                                }}>
                                                  <div>
                                                    <>
                                                      <HoverVideoPlayer
                                                        className="my-1 cursor-pointer "
                                                        videoSrc={curElem.url}
                                                        pausedOverlay={
                                                          <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                        }
                                                        loadingOverlay={
                                                          <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                        }
                                                      />
                                                      <FiTrash2
                                                        className='remove-media'
                                                        onClick={(e) => openDeletePopUp(e, curElem.id, 'intro')}
                                                      />
                                                    </>
                                                  </div>

                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <div className='text-center' style={{ fontSize: 20 }}>
                                            You don't have uploaded Intro videos
                                          </div>}
                                      </div>


                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>

                                <Tab.Content>
                                  <Tab.Pane eventKey="Videoreel-video">
                                    <div className="innertab  credit-modal" style={{ paddingRight: '20px' }}>
                                      <div className="row gy-4">
                                        {videoReel.length > 0 ?
                                          videoReel.map((curElem, index) => {
                                            if (curElem.category === "intro") {
                                              return (
                                                <div
                                                  className="col-4 ps-2 pe-2"
                                                  key={index}
                                                  onClick={() => selectIntro(curElem, "videoReel")}
                                                >
                                                  <div className="credit-video-card mt-2" style={{
                                                    border: "none",
                                                  }}>
                                                    <HoverVideoPlayer
                                                      className="cursor-pointer "
                                                      videoSrc={curElem.prview_video_url}
                                                      pausedOverlay={
                                                        <img src={curElem.preview_image_url} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                      }
                                                      loadingOverlay={
                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            }
                                          })
                                          :
                                          <div className='text-center' >
                                            {social.videoreel.length > 0 ?
                                              <div>
                                                You haven't created your VideoReel Intro Videos yet
                                              </div>
                                              : <div className="alert alert-warning" role="alert" style={{ width: "80%", margin: "0 auto" }}>
                                                You haven't connected your VideoReel account yet.<br /> Click <a className="custom-anchor"
                                                  onClick={() => connectVideoReel()}
                                                >Here</a> to connect and use those VideoReel Intro Videos
                                              </div>}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={button}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => onConfirm()}
                        onCancel={() => setShowDelete(false)}
                        focusCancelBtn
                        show={showDelete}
                      >
                        You want to delete this file
                      </SweetAlert>

                      <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Connect"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => onConfirmVideoReel()}
                        onCancel={() => setShowDeleteVideoReel(false)}
                        focusCancelBtn
                        show={showDeleteVideoReel}
                      >
                        Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
                      </SweetAlert>

                    </Modal>

                  </div>
                </> : ''}
            </div>
          </div>


          <Outro
            videoReel={videoReel}
            chapter={chapter}
            socialLength={social.videoreel.length}
            addDataInCourseChap={addDataInCourseChap}
          />
        </div >
      </div > */}

    </>
  );
}

export default Credit;
