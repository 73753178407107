import React, { useEffect } from "react";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { FiTrash2, FiUpload } from "react-icons/fi";
import PromoVoice from "./PromoVoice";
import VisualStyle from "./VisualStyle";
import TextAnime from "./TextAnime";
import PromoBackground from "./PromoBackground";
import TitleBar from "../../CommonComponents/TitleBar";
import Navbar from "../../CommonComponents/Navs/Navbar";
import SideNav from "../../CommonComponents/Navs/SideNav";
import Footer from "../../CommonComponents/Footer/Footer";


const PromoEditor = () => {

    return (
        <>
            <TitleBar title="Dashboard" />

            <div className="navToggle-head">
              <Navbar />
            </div>

            <section className="siteWrap navToggle">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Back</button>
                                        <button className="demoLink">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="containern">
                           <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="promo-editor">
                                    <div className="promo-editor-left">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <VisualStyle />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <TextAnime />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <PromoBackground />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <PromoVoice />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                    <div className="promo-editor-right">
                                        <div className="promo-editor-right-in">
                                            <h2 className="text-center gradientTxt"><span>Customize Promo Video Elements</span></h2>
                                            <div className="text-center">
                                                <Nav variant="n" className="edit-nav">
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        <img src={require('../../../images/editor/eye.svg').default} />
                                                        <p>Visual Style & Themes</p>
                                                    </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        <img src={require('../../../images/editor/text-2.svg').default} />
                                                        <p>Text Overlays & Animation</p>
                                                    </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="third">
                                                        <img src={require('../../../images/editor/music-2.svg').default} />
                                                        <p>Background Music & Sound Effects</p>
                                                    </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="fourth">
                                                        <img src={require('../../../images/editor/voice-over.svg').default} />
                                                        <p>Voiceover</p>
                                                    </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>

                                            <div className="promo-vid-controll">
                                                <span><img src={require('../../../images/editor/up.svg').default} /></span>
                                                <span><img src={require('../../../images/editor/down.svg').default} /></span>
                                                <span><img src={require('../../../images/editor/copy.svg').default} /></span>
                                                <span><img src={require('../../../images/editor/delete.svg').default} /></span>
                                            </div>

                                            <div className="promo-vid-canvas">
                                                <div className="promo-vid-prev"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                <div className="promo-vid-controller">
                                                    <span>1/4</span>
                                                    <div className="promo-vid-play">
                                                        <span><img src={require('../../../images/editor/prev.svg').default} /></span>
                                                        <span><img src={require('../../../images/editor/play.svg').default} /></span>
                                                        <span><img src={require('../../../images/editor/next.svg').default} /></span>
                                                    </div>
                                                    <span>00:00:10</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> 
                           </Tab.Container>

                           <div className="promo-timeline-outer">
                              <div className="promo-timeline">
                                <div className="promo-timeline-left">
                                    <div className="layer-icon">
                                        <span><img src={require('../../../images/editor/play-2.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/eye-2.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/mic.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/lock.svg').default} /></span>
                                    </div>
                                    <hr />
                                    <div className="layer-icon mt-3">
                                        <span><img src={require('../../../images/editor/text-3.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/eye-2.svg').default} /></span>
                                        <span className="disable"><img src={require('../../../images/editor/mic-disable.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/lock.svg').default} /></span>
                                    </div>
                                    <div className="layer-icon mt-3">
                                        <span><img src={require('../../../images/editor/text-3.svg').default} /></span>
                                        <span className="disable"><img src={require('../../../images/editor/eye-disable.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/mic.svg').default} /></span>
                                        <span className="disable"><img src={require('../../../images/editor/unlock.svg').default} /></span>
                                    </div>
                                    <div className="layer-icon mt-3">
                                        <span><img src={require('../../../images/editor/music-3.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/eye-2.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/mic.svg').default} /></span>
                                        <span><img src={require('../../../images/editor/lock.svg').default} /></span>
                                    </div>
                                </div>

                                <div className="promo-timeline-right">
                                    <div className="promo-timeline-right-scroll">
                                        <div className="promo-timeline-thumb">
                                            <ul>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="promo-timeline-thumb-single">
                                                        <div className="timeline-thumb-img"><img src="https://images.pexels.com/photos/347134/pexels-photo-347134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        <div className="thumb-icon">
                                                            <span><FiTrash2 /></span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <hr />

                                        <div className="promo-text-layer" style={{width: 2000}}>
                                            <div className="promo-text-layer-single">Okay</div>
                                            <div className="promo-text-layer-single">This is serious</div>
                                            <div className="promo-text-layer-single">What happens</div>
                                            <div className="promo-text-layer-single">in Vegas, stays</div>
                                            <div className="promo-text-layer-single">in Vegas</div>
                                        </div>

                                        <div className="promo-text-layer mt-3" style={{paddingLeft: '550px', width: 2000}}>
                                            <div className="promo-text-layer-single">Okay</div>
                                            <div className="promo-text-layer-single">This is serious</div>
                                            <div className="promo-text-layer-single">What happens</div>
                                            <div className="promo-text-layer-single">in Vegas, stays</div>
                                            <div className="promo-text-layer-single">in Vegas</div>
                                        </div>

                                        <div className="promo-mp3-layer mt-3">
                                            <div className="promo-mp3-layer-single">
                                                <span className="mp3-tag">Audio name here.mp3</span>
                                            </div>
                                            <div className="promo-mp3-layer-single">
                                                <span className="mp3-tag">Audio name here.mp3</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                              </div>
                           </div> 
                           
                        </div>
                    </div>
                </div>
                
            </section>
            
            <Footer />
        </>
    )
}

export default PromoEditor;