import React, { useState } from 'react'
import { IoCopyOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { FaDesktop } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';


const ReviewParticipantsName = () => {
    

    return (
        <>
            <TitleBar title="Quiz" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Save as Draft</button>
                                        <button className="demoLink">Publish</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt'><span>Detailed Response Review for  Participants</span></h2>

                                <div className='quiz-card mt-5'>
                                    <h6 className='text-center pt-2'>Analyze detailed responses for all your participants</h6>


                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    
                                            <div className='text-center mt-5'>
                                                <Nav variant="v" className="tabStyleTwo">
                                                    <Nav.Item>
                                                      <Nav.Link eventKey="first"><MdPhoneIphone /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                      <Nav.Link eventKey="second"><FaDesktop /></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                            
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                <div className='row pt-5'>
                                    <div className='col-sm-6'>

                                        <div className='quiz-prev-outer'>
                                        <div className='quiz-prev-wrap'>
                                            <div className='gradient-box'>
                                            <div className='quiz-prev-in'>
                                                <div className='quiz-prev-box'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span>Question 1/50</span>
                                                    <span>00:59</span>
                                                </div>
                                                <div className='edit-area mt-3'>
                                                    <BiEditAlt />
                                                    <p>Which planet in our solar system is known as the "Red Planet"?</p>
                                                </div>
                                                </div>

                                                <p style={{fontSize: '0.8rem'}} className='text-center mt-3'>Double Click Text to Edit</p>

                                                <div className='selected-ans'>
                                                <div className='selected-ans-single active'>Venus <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Mars <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Jupiter <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Mercury <MdDragIndicator /></div>
                                                </div>

                                                <div className='row pt-5'>
                                                <div className='col-6'>
                                                    <button className='qz-button'>&lt;&lt; Prev</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button className='qz-button blue'>Next &gt;&gt;</button>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='quiz-prev-nav'><img src={require('../../images/quiz/nav-prev.svg').default} alt='' /></div>
                                        <div className='quiz-prev-nav alt'><img src={require('../../images/quiz/nav-next.svg').default} alt='' /></div>
                                        </div>

                                    </div>

                                    <div className='col-sm-6'>
                                        <div className='quiz-prev-wrap'>
                                           <h6 className='gradientTxt'><span>Aggregated Metrics</span></h6>
                                           <div className='metrics mt-3'>
                                              <div className='metrics-single'>
                                                <h6>Average score of participants</h6>
                                                <h3 className='pt-1'>35/50</h3>
                                              </div>

                                              <div className='gradient-box'>
                                                <div className='metrics-single'>
                                                    <h6>Success Rate for <strong>Question 01</strong></h6>
                                                    <h3 className='pt-1'>90%</h3>
                                                </div>
                                              </div>
                                           </div>
                                        </div>

                            <div className='mt-4'>
                                <div className='question-number'>

                                    <ul className='question-number-list small'>
                                        <li>
                                        <div className='gradient-box active'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>01</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>02</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>03</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>04</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>05</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>06</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>07</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>08</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>09</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>10</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>11</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>12</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>13</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>14</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>15</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>16</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>17</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>18</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>19</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>20</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>21</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>22</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>23</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>24</div>
                                            </div>
                                        </div>
                                        </li>
                                    </ul>
                                    </div>
                                        </div>
                                    </div>

                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                <div className='row pt-5'>
                                    <div className='col-sm-12'>

                                        <div className='quiz-prev-outer large'>
                                        <div className='quiz-prev-wrap large'>
                                            <div className='gradient-box'>
                                            <div className='quiz-prev-in'>
                                                <div className='quiz-prev-box'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span>Question 1/50</span>
                                                    <span>00:59</span>
                                                </div>
                                                <div className='edit-area mt-3'>
                                                    <BiEditAlt />
                                                    <p>Which planet in our solar system is known as the "Red Planet"?</p>
                                                </div>
                                                </div>

                                                <p style={{fontSize: '0.8rem'}} className='text-center mt-3'>Double Click Text to Edit</p>

                                                <div className='selected-ans'>
                                                <div className='selected-ans-single active'>Venus <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Mars <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Jupiter <MdDragIndicator /></div>
                                                <div className='selected-ans-single'>Mercury <MdDragIndicator /></div>
                                                </div>

                                                <div className='row pt-5'>
                                                <div className='col-6'>
                                                    <button className='qz-button'>&lt;&lt; Prev</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button className='qz-button blue'>Next &gt;&gt;</button>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='quiz-prev-nav'><img src={require('../../images/quiz/nav-prev.svg').default} alt='' /></div>
                                        <div className='quiz-prev-nav alt'><img src={require('../../images/quiz/nav-next.svg').default} alt='' /></div>
                                        </div>

                                    </div>

                                    <div className='col-sm-12'>
                                        <div className='quiz-prev-wrap large mt-4'>
                                           <h6 className='gradientTxt'><span>Aggregated Metrics</span></h6>
                                           <div className='metrics mt-3'>

                                              <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className='metrics-single'>
                                                        <h6>Average score of participants</h6>
                                                        <h3 className='pt-1'>35/50</h3>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='gradient-box'>
                                                        <div className='metrics-single'>
                                                            <h6>Success Rate for <strong>Question 01</strong></h6>
                                                            <h3 className='pt-1'>90%</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>

                                              

                                              
                                           </div>
                                        </div>

                            <div className='mt-4'>
                                <div className='question-number'>

                                    <ul className='question-number-list'>
                                        <li>
                                        <div className='gradient-box active'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>01</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>02</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>03</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>04</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>05</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>06</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box correct'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>07</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>08</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>09</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>10</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>11</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>12</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>13</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>14</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>15</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>16</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>17</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>18</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>19</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>20</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>21</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>22</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box incorrect'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>23</div>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className='gradient-box'>
                                            <div className='question-number-single'>
                                            <div className='question-number-single-in'>24</div>
                                            </div>
                                        </div>
                                        </li>
                                    </ul>
                                    </div>
                                        </div>
                                    </div>

                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                            
                                    </Tab.Container>


                                </div>

                            </div>
                        </div>

                        
                    </div>
                </div>

                
            </section>
            
            <Footer />

        </>
    )
}

export default ReviewParticipantsName;