import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { onChangeAnswerType, onChangeQuestionType } from '../../Redux/Actions/QuizActions'

const QuestionTypes = () => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)

  const handleChangeAnswerType = (e) => {
    dispatch(onChangeAnswerType(e.target.value))
  }

  const handleChangeQuestionType = (e) => {
    dispatch(onChangeQuestionType(e.target.name, e.target.checked))
  }

  return (
    <>
      <div className='quiz-card'>
        <h5 className='gradientTxt'><span>Select The Type of Questions to Include in The Quiz</span></h5>
        <h6 className='pt-4'>Multiple-Choice Questions (MCQs):</h6>
        <p>User can choose between Single or Multiple Correct Answers</p>

        <div className='question-type'>
          <ul>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Single Correct Answer</h6>
                  </div>
                </div>
                <input
                  type="radio"
                  name='answereChoice'
                  checked={quiz.questionType.answereChoice === "single"}
                  onChange={handleChangeAnswerType}
                  value={"single"}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Multiple Correct Answers</h6>
                  </div>
                </div>
                <input
                  type="radio"
                  name='answereChoice'
                  checked={quiz.questionType.answereChoice === "multiple"}
                  onChange={handleChangeAnswerType}
                  value={"multiple"}
                />
              </div>
            </li>
          </ul>
        </div>


        <h6 className='pt-4'>Other Options</h6>
        <p>Select one or more option to make your quiz more versatile</p>

        <div className='question-type'>
          <ul>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>True/False Questions:</h6>
                    <p>Binary options for quick assessments.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='trueFalse'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.trueFalse}
                />
              </div>
            </li>

            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Short Answer Questions:</h6>
                    <p>Open-ended responses to test understanding.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='shortAnswerQuestion'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.shortAnswerQuestion}
                />
              </div>
            </li>

            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Fill-in-the-Blanks:</h6>
                    <p>AI highlights key phrases for learners to complete.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='fillInTheBlank'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.fillInTheBlank}
                />
              </div>
            </li>

            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Matching Questions:</h6>
                    <p>Pair terms with definitions or outcomes.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='matchingQuestions'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.matchingQuestions}
                />
              </div>
            </li>

            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Image-Based Questions:</h6>
                    <p>AI uses images to create visual identification questions.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='imageBasedQuestions'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.imageBasedQuestions}
                />
              </div>
            </li>

            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Scenario-Based Questions:</h6>
                    <p>Situational questions to test practical application.</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='scenarioBasedQuestion'
                  onChange={handleChangeQuestionType}
                  checked={quiz.questionType.questionChoice.scenarioBasedQuestion}
                />
              </div>
            </li>

          </ul>
        </div>

      </div>
    </>
  )
}

export default QuestionTypes