import React, { useEffect, useState } from 'react'
import CommonAlert from '../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router'
import PublishModal from './Publish/PublishModal'
import videoThumb from '../../images/fav-new.png';
import { onCheckStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { onCheckSlides, onCheckTranscribe, onSaveChapter } from '../../Redux/Actions/CourseActions'


const ChapterCard = ({ curElem, fetchCourse, promoVideo, setPromoVideo, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector((state) => state.auth);
    const [state, setState] = useState(curElem.status)
    const [loader, setLoader] = useState({
        check: false
    })
    const [showModel, setShowModel] = useState(false)
    const [checkData, setCheckData] = useState({
        renderProgress: 0,
        renderStatus: ""
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        status: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "chapters"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, promoVideo, setPromoVideo, setSweet, fetchCourse))

    }

    const handlePublish = () => {
        setShowModel(true)
    }

    // const handleRedirect = () => {
    //     setLoader({
    //         ...loader,
    //         check: true
    //     })
    //     let data = {
    //         courseId: curElem.cid,
    //         chapterId: curElem.id
    //     }
    //     dispatch(onCheckSlides(data, navigate, loader, setLoader))
    // }

    const handleSaveTranscribe = (data) => {
        let obj = {
            courseId: curElem.cid,
            chapterId: curElem.id,
            text: data.transcribeText,
            slideBreak: "separator",
            buttonType: "save",
            createdFrom: {
                enable: true,
                type: curElem.createdFrom.type === "music" ? "audio" : curElem.createdFrom.type,
                source: data.transcript_uri,
                transcribeId: data.transcribeId,
                text: data.transcribeText
            }
        }
        dispatch(onSaveChapter(obj))
    }
    const checkTranscribe = (intCheck) => {
        let data = {
            courseId: curElem.cid,
            chapterId: curElem.id
        }
        dispatch(onCheckTranscribe(data, handleSaveTranscribe, setCommon, intCheck))
    }


    const fetchVideo = (id, interval) => {
        let data = {
            chapterId: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }


    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let activeTrans = false
            let progress = 100
            videoUrl = curElem.videoUrl
            switch (+curElem.status) {
                case 0:
                    text = "Awaiting Script"
                    activeIntrval = false
                    canEdit = type === "promo" ? true : false
                    url = type === "promo" ? `/chapter-summaries?id=${curElem.id}` : ""
                    break;
                case 1:
                    text = "Generating Video..."
                    showProg = true
                    activeIntrval = true
                    break;
                case 2:
                    text = "Render Successful"
                    canEdit = true
                    url = `/editor?id=${curElem.id}`
                    break;
                case 3:
                    canEdit = true
                    text = "Rendering Failed"
                    url = `/editor?id=${curElem.id}`
                    break;
                case 4:
                    canEdit = true
                    text = "Script Generated"
                    // url = "create-script"
                    break;
                case 5:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = `/editor?id=${curElem.id}`
                    progress = curElem.renderProgress
                    break;
                case 6:
                    canEdit = true
                    text = "Awaiting Customization"
                    url = `/editor?id=${curElem.id}`
                    break;
                case 7:
                    canEdit = true
                    text = "Transcribing Failed"
                    activeIntrval = false
                    break;
                case 8:
                    canEdit = true
                    text = "Script Generated"
                    url = type === "promo" ? `/review-script?id=${curElem.id}` : `/write-chapters?id=${curElem.cid}&chapId=${curElem.id}`
                    break;
                case 9:
                    text = "Transcribing..."
                    activeTrans = true
                    break;

                case 10:
                    text = "Downloading Resources..."
                    canEdit = true
                    activeIntrval = true
                    progress = curElem.renderProgress
                    break;
                case 11:
                    canEdit = true
                    text = "Waiting in Queue..."
                    activeIntrval = true
                    showProg = true
                    url = `/editor?id=${curElem.id}`
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }

            setCommon({
                ...common,
                statusText: text,
                status: +curElem.status,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnail,
                failedData: curElem.logFile ? JSON.parse(curElem.logFile) : false,
                progress: progress
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }

            if (activeTrans) {
                const intCheck = setInterval(() => {
                    checkTranscribe(intCheck)
                }, 5000)

            }
        }
    }, [curElem])



    // useEffect(() => {
    //     if (+curElem.status === 9) {
    //         setState(+curElem.status)
    //         const intCheck = setInterval(() => {
    //             checkTranscribe(intCheck)
    //         }, 5000)

    //         return () => {
    //             clearInterval(intCheck)
    //         }
    //     } else if (+curElem.status === 4 || +curElem.status === 5 || +curElem.status === 10 || +curElem.status === 11) {
    //         const rendCheck = setInterval(() => {
    //             // checkRendering(rendCheck)
    //         }, 5000)
    //         return () => {
    //             // clearInterval(rendCheck)
    //         }
    //     }
    // }, [curElem])

    return (
        <>
            <div className="videoProject-single">
                <div className="videoProject-single-top">
                    <div className="video-card-wrapper-1 dashboard-card-bg">
                        {common.thumbUrl ?
                            <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} /> : null}
                        <div className="video-card-content">
                            <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                        </div>
                        <span className={`qz-tag ${+common.status === 2 ? "active" : ""}`}>
                            {common.showRendering ? <>Rendering {common.progress}%</> : common.statusText}
                        </span>
                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.create}</p></div>
                        <div className="vidiconAll">
                            {common.isEditabel ?
                                <div className="vidHover-single">
                                    <Link to={common.urlToRedirect}>
                                        <span className="vidHover-icon">
                                            {loader.check ? <i className="fa fa-spinner fa-spin" /> :
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>}
                                        </span>
                                        <span>Edit</span>
                                    </Link>
                                </div> : ""}
                            <div className="vidHover-single">
                                <a onClick={onDelete}>
                                    <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                    <span>Delete</span>
                                </a>
                            </div>
                            {common.isDownloadable ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={`${curElem.videoUrl}`}>
                                            <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {auth.user.isClientAccount !== "1" ?
                                        <div className="vidHover-single cursor-pointer">
                                            <a onClick={handlePublish} >
                                                <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                <span>Publish</span>
                                            </a>
                                        </div> : ""}
                                </> : ""}
                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <div>
                        <h6>{curElem.name}</h6>
                        {/* <p>Date Created: 12-01-24</p> */}
                    </div>
                </div>
            </div>



            {/* <div className="videoProject-single">
                <div className="videoProject-single-top">
                    <div className={+state === 9 ? "video-card-wrapper" : +state === 8 ? "video-card-wrapper-1" : +state === 7 ? "video-card-wrapper-2" : "video-card-wrapper-1"}>
                        <div className="video-card-content">
                            {+state === 4 ?
                                <div className="row" style={{ width: "100%" }}>
                                    <div className=" col-7 progress-content text-white ">
                                        <span style={{ fontSize: '14px', color: "#fff" }}>Your Progress</span>
                                        <h5 style={{ fontSize: '14px', color: "#fff " }}>{checkData.renderStatus}</h5>
                                    </div>
                                    <div className="col-5 ">
                                        <div className="progress-status text-white float-end">
                                            <span style={{ fontSize: '17px', color: "#fff", fontWeight: '600' }}>{checkData.renderProgress}%</span>
                                            <h5 style={{ fontSize: '14px', color: "#fff " }}>Completed</h5>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="progress-bar-status">
                                            <div className="progress " style={{ backgroundColor: ' rgb(0 0 0 / 47%)' }}>
                                                <div className=" progress-bar-render progress-bar-striped bg-danger progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100%" style={{ width: `${parseInt(checkData.renderProgress)}%` }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                +state === 6 ?
                                    <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} />
                                    :
                                    <>
                                        <img className={`mb-3 vidThumb-img  img-fluid ${+state === 9 ? "vidThumb-img-1" : ''}`} src={videoThumb} />
                                        <p className={`font-italic mb-0 ${state !== 9 ? "text-light" : ''}`} style={{ fontWeight: "600" }}>
                                            {common.isTrans ?
                                                <div className="dots-wrapper">
                                                    <a style={{ paddingRight: "3px" }}>{common.statusText}</a>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                </div> :
                                                common.statusText
                                            }
                                        </p>
                                    </>
                            }

                        </div>

                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">
                            {auth.user.isClientAccount !== "1" ?
                                <div className="vidHover-single">
                                    {+state === 9 ? "" :
                                        <a onClick={handleRedirect} >
                                            <span className="vidHover-icon">
                                                {loader.check ? <><i className="fa fa-spinner fa-spin" /></> : <i className="fa fa-pencil-square-o" aria-hidden="true" />}
                                            </span>
                                            <span>Edit</span>
                                        </a>
                                    }
                                </div>
                                : ""}
                            {auth.user.isClientAccount !== "1" ?
                                <div className="vidHover-single cursor-pointer ps-2">
                                    <a onClick={onDelete}>
                                        <span className="vidHover-icon"><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                </div> : ""}

                            {curElem.status === "6" ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={`${curElem.videoUrl}`}>
                                            <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {auth.user.isClientAccount !== "1" ? <div className="vidHover-single cursor-pointer">
                                        <a onClick={handlePublish} >
                                            <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                            <span>Publish</span>
                                        </a>
                                    </div> : ""}
                                </>
                                : ''}

                        </div>
                    </div>

                </div>
                <div className="videoProject-single-bottom p-0">
                    <div className="videoProject-single-bottom-desc p-0">
                        <span className='videoProject-desc'>{curElem.name}</span>
                    </div>
                </div>
            </div> */}

            <PublishModal
                showModel={showModel}
                setShowModel={setShowModel}
                curElem={curElem}

            />

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this Chapter "${curElem.name}"`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </>
    )
}

export default ChapterCard