import React, { useState } from 'react'
import { IoCopyOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import QuestionsBox from './QuestionsBox'
import { onDeleteQuestion, onReorderQuestions } from '../../../Redux/Actions/QuizActions'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { GrDocumentPdf } from "react-icons/gr";
import GeneratePdf from '../../CommonComponents/GeneratePdf'
import { appName } from '../../../Global/Global'
import QuizPdfView from './QuizPdfView'
import { setAlert } from '../../../Redux/Actions/AlertActions'
const MobileView = ({ view, activeIndex, setActiveIndex }) => {
    const dispatch = useDispatch()
    const quiz = useSelector(state => state.quiz.data)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [isAnswerKey, setIsAnswerKey] = useState(false)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(quiz.quizData, result.source.index, result.destination.index);
        dispatch(onReorderQuestions(items))
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`https://coursereelx.reelapps.io/quiz?id=${quiz.id}`);
        dispatch(setAlert("Quiz URL is copied!", "success"))
    }


    const deleteQuestion = (indexToDelete) => {
        if (+indexToDelete === +activeIndex) {
            if (indexToDelete === quiz.quizData.length - 1) {
                setActiveIndex(indexToDelete - 1)
            } else {
                setActiveIndex(indexToDelete + 1)
            }
        }
        dispatch(onDeleteQuestion(indexToDelete))
    }

    return (
        <>
            <div className='row pt-5'>
                <div className={`col-sm-${view === "mobile" ? 6 : 12}`} >
                    {quiz.quizData.length > 0 ?
                        quiz.quizData.map((curElem, index, arr) => {
                            return (
                                index === activeIndex ?
                                    <React.Fragment key={index}>
                                        <QuestionsBox
                                            curElem={curElem}
                                            length={arr.length}
                                            index={index}
                                            setActiveIndex={setActiveIndex}
                                            activeIndex={activeIndex}
                                            view={view}
                                            deleteQuestion={deleteQuestion}
                                        />
                                    </React.Fragment> : null
                            )
                        })
                        : ""}
                </div>
                <div className={`col-sm-${view === "mobile" ? 6 : 12}`}>
                    <div className={`quiz-prev-wrap ${view === "mobile" ? "" : "large"}`} style={{ height: '100%' }}>
                        <h6 className='gradientTxt'><span>Save and Publish Options</span></h6>
                        <div className='question-type full '>
                            <ul>
                                <li className='px-3'>
                                    <div className='question-type-single' onClick={handleShow}>
                                        <div className='d-flex'>
                                            <div className='col' >
                                                <h6><GrDocumentPdf className='me-3' /> Show as a PDF</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <label style={{ cursor: "pointer", marginTop: 15 }}>
                                        <input
                                            type='checkbox'
                                            checked={isAnswerKey}
                                            onChange={(e) => setIsAnswerKey(e.target.checked)}

                                        /> Include answer keys
                                    </label>
                                </li>
                            </ul>
                        </div>

                        <div className='quizLink mt-4'>
                            <div className="row">
                                <div className="col">
                                    <h6>Quiz Link</h6>
                                    <p className='pt-2 pb-4' style={{ fontSize: '0.8rem' }}>Share your Quiz link to allow participants make attempt on quizzes</p>
                                    <a style={{ fontSize: '0.8rem', color: '#0077FF', cursor:"pointer" }} onClick={handleCopyLink}><IoCopyOutline /> <strong>Copy Quiz Link</strong></a>
                                </div>
                                <div className="col-auto">
                                    <img src={require('../../../images/quiz/prev.png')} alt='' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='question-number pt-5'>
                <p className='pb-2' style={{ fontSize: '0.9rem' }}>Drag  each question number to reorder</p>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable" direction='horizontal'>
                        {(provided) => (
                            <ul
                                className='question-number-list'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {quiz.quizData.length > 0 ?
                                    quiz.quizData.map((curElem, index) => {
                                        return (
                                            <React.Fragment>
                                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                    {(provided) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => setActiveIndex(index)}
                                                            style={{ cursor: "pointer" }}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className={`gradient-box ${index === activeIndex ? "active" : ""}`}>
                                                                <div className='question-number-single'>
                                                                    <div className='question-number-single-in'>{index + 1}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            </React.Fragment>
                                        )
                                    })
                                    : ""}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <QuizPdfView
                show={show}
                handleClose={handleClose}
                data={quiz.quizData}
                name={quiz.name}
                id={"quizDataVal"}
                showAnswer={isAnswerKey}
            />

        </>
    )
}

export default MobileView