import React, { useEffect, useState } from "react";
import HelpContent from "./HelpContent";
import HelpVideos from "./HelpVideos";
import { useDispatch } from "react-redux";
import CustomerSupport from "./CustomerSupport";
import Navbar from "../CommonComponents/Navs/Navbar";
import Footer from "../CommonComponents/Footer/Footer";
import { fetchArticles, fetchVideos } from "../../Redux/Actions/HelpActions";
import TitleBar from "../CommonComponents/TitleBar";

const Help = () => {

    const dispatch = useDispatch()

    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help & Support" />

            <div className="navFull"><Navbar /></div>

            <HelpContent loadArticles={loader.article} />
            <HelpVideos loadVideos={loader.video} />
            <CustomerSupport />

            <div className="footerFull">
                <Footer />
            </div>
        </>
    )
}

export default Help;