import React from 'react'

const Platform = ({ data }) => {
    return (
        data.length > 0 ?
            <>
                <optgroup label={data[0].group_name}>
                    {data.map((curElem, index) => {
                        return (
                            <option value={curElem.dimension} key={index}>{curElem.name}</option>
                        )
                    })
                    }
                </optgroup>
            </>
            : ""
    )
}

export default Platform