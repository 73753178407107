import React, { useState } from 'react'
import Navbar from '../CommonComponents/Navs/Navbar'
import TitleBar from '../CommonComponents/TitleBar'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { FiTrash2 } from 'react-icons/fi'

const ReelMerge = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        user_id: auth.user.id
    })
    const [videoData, setVideoData] = useState([])
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);
    const [loader, setLoader] = useState({
        dataLoader: false,
        mergeLoader: false,

    });

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            mergeLoader: true
        });
        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0].preview_image;
        // axios({
        //     method: 'POST',
        //     url: `${baseURL}create-merge`,
        //     data: mergeData,
        //     headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        // }).then(res => {
        //     if (res.data.status === true) {
        //         history.push('/projects');
        //         dispatch(setAlert(res.data.message, 'success'));
        //     } else {
        //         dispatch(setAlert(res.data.message, 'danger'));
        //     }
        //     setLoader({
        //         ...loader,
        //         mergeLoader: false
        //     });
        // }).catch(err => {
        //     console.log(err)
        //     setLoader({
        //         ...loader,
        //         mergeLoader: false
        //     });
        // })
    }

    const handleMerge = (item) => {

        let jsonObj = {
            id: item.chapterId,
            name: item.chapName,
            preview_image: item.thumb,
            url: item.url
        }

        setCurrentSlide(jsonObj);
        setMergeVideos([...mergeVideos, jsonObj]);
    }

    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );
        setMergeVideos(items);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const activeSlide = (video) => {
        setCurrentSlide(video)
    }

    const deleteSlide = (index) => {
        let removeArr = [...mergeVideos];
        removeArr.splice(index, 1);

        setMergeVideos(removeArr);
    }

    return (
        <>
            <TitleBar title="Reel Merge" />
            <div className='navFull'>
                <Navbar />
            </div>

            <section className="siteWrap reelsitewrap">
                <div className="campaignBar for-editor justify-content-between">
                    <h2 className='pageTitle gradientTxt'><span>Title of Project Here</span></h2>
                    <form method="post" onSubmit={(e) => handleFormSubmit(e)} className="d-flex align-items-center gap-2 projectTitle-right">
                        <div className="project-input-box">
                            <div className='project-input-in'>
                                <input
                                    type="text"
                                    className="inp"
                                    placeholder="Add Campaign Name"
                                    defaultValue={mergeData.name}
                                    required
                                    onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                />
                            </div>
                        </div>

                        <button type="submit" className="demoLink" disabled={mergeVideos.length > 1 ? false : true} >
                            <span className="mr-1">
                                {loader.mergeLoader ?
                                    <>
                                        Merging
                                        <i className="fa fa-spinner fa-spin mx-1" />
                                    </> :
                                    <>
                                        <i className="fas fa-file-video mx-2" />
                                        Merge

                                    </>
                                }
                            </span>

                        </button>
                    </form>
                </div>

                <div className="editorWrap reelmergeWrap myeditorWrap">
                    <div className="editor-left">
                        <div className="row p-2 ">
                            {
                                videoData.length > 0 ?
                                    videoData.map((curElem, index) => {
                                        return (

                                            <div className="col-6 cursor-pointer mb-2 px-1 text-center merge-wrap-img" key={index} onClick={() => handleMerge(curElem)}>
                                                <div className="mediaList-single myImage-respo " style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={curElem.thumb}
                                                        alt=""
                                                        className="img-fluid"
                                                        style={{ width: "100%", height: "100%" }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : !loader.dataLoader ?
                                        <div className="text-center" style={{ width: '100%' }}>
                                            <h5 className="text-white">No Videos Created</h5>
                                        </div> : ''
                            }
                            {loader.dataLoader ?
                                <div className="col-md-12">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : ''
                            }

                        </div>
                    </div>
                    <div className="editor-right">


                        <div className='canvasEditor'>
                            <div className="canvasEditor-main">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={currentSlide.url}
                                    playing={true}
                                    muted={true}
                                    loop={true}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </section >
            {/* Reel Tiles */}
            <section className="slide-sec-merge">
                <div className="container-fluidn">
                    <div className="rown">
                        <div className="col-md-12n">
                            <div className="slide-box">
                                <DragDropContext onDragEnd={onSetRecorder}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided) => (
                                            <ul className="d-flex list-inline align-items-center m-0"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    mergeVideos.length > 0 ?
                                                        mergeVideos.map((video, index) => {
                                                            return (
                                                                <Draggable key={index} draggableId={`id_${index}`}
                                                                    index={index}>
                                                                    {(provided) => (
                                                                        <li
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={index}
                                                                            style={{ border: `${currentSlide.url === video.url ? '2px solid #e512c9' : ''}` }}
                                                                        >
                                                                            <div
                                                                                style={{ position: "relative", width: "100%", height: "100%" }}
                                                                                className='box-ar-wrapper'
                                                                            >
                                                                                <img
                                                                                    src={video.preview_image}
                                                                                    alt=""
                                                                                    className="img-fluid"
                                                                                    onClick={() => activeSlide(video)}
                                                                                />
                                                                                <FiTrash2
                                                                                    className="merge-delete"
                                                                                    color='purple'
                                                                                    onClick={() => deleteSlide(index)}
                                                                                />
                                                                            </div>

                                                                        </li>
                                                                    )
                                                                    }
                                                                </Draggable>
                                                            )
                                                        })
                                                        : <li></li>
                                                }
                                                {provided.placeholder}
                                            </ul>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ReelMerge