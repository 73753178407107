import React, { useEffect, useState } from 'react'
import { FaRegCirclePlay, FaRegCircleStop, FaSpinner } from 'react-icons/fa6'
import { FiTrash2 } from 'react-icons/fi'
import { IoMicOutline } from 'react-icons/io5'
import useRecorder from '../../../CommonComponents/MyRecording'

let newIntervalId, music = false
const Recording = ({ onInputFile, loaderRecord }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const [play, setPlay] = useState(false)
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false,
        playRecording: false
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            setT(false)
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            setLoader({
                ...loader,
                playRecording: true
            })
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    const handleRemoveRecording = () => {
        setAudioURL("")
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }

    const handleUpload = () => {
        const fileName = new File([audioURL], "Recording", { type: "audio/mpeg" });
        let obj = {
            target: {
                files: [fileName]
            }
        }
        onInputFile(obj, "recording")
    }


    return (
        <>
            <div className="or-style my-5">
                <span>Or</span>
            </div>



          <div className='modal-block'>

          <div className="add-block-title pb-4">
                <h6>Recording</h6>
            </div>

            

            {
                status.start ?
                    <div className="dashboard-block mt-3 ">
                        <div className='record-action d-flex justify-content-center align-items-center' onClick={() => handleClick("stop")} style={{ cursor: "pointer" }} >
                            <div className='embed-user-icon fill me-2'><FaRegCircleStop /></div>
                            <h6><strong>Stop Recording</strong> |

                                {
                                    timer.h < 10 ? '0' + timer.h : timer.h
                                }
                                :
                                {
                                    timer.m < 10 ? '0' + timer.m : timer.m
                                }
                                :
                                {
                                    second < 10 ? '0' + second : second
                                }
                            </h6>
                        </div>
                    </div>

                    :
                    <div className="dashboard-block">
                        <div className="row align-items-center">
                            <div className="col" onClick={() => handleClick("start")} style={{ cursor: "pointer" }}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="embed-user-icon fill"><IoMicOutline /></div>
                                    </div>
                                    <div className="col ps-0">
                                        <h6 className="fw-700" style={{ fontSize: "13px", marginBottom: "2px" }}>Record Your Voice</h6>
                                        <p style={{ fontSize: "12px" }}>Click Here to Record Your Voice</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                {/* <button className="theme-btn bdr" onClick={handleShow}>
                                    <span>Create</span>
                                </button> */}
                            </div>
                        </div>
                    </div>
            }

            <div className='embed-list'>
                {audioURL ?
                    [audioURL].map((curElem, index) => {
                        return (
                            <div className='embed-single count-2' key={index}>
                                <div className='embed-user-txt'>
                                    <h6>Recorded Voice</h6>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className=' fill'>
                                        {play ?
                                            <FaRegCircleStop size={23} style={{ cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                                            <FaRegCirclePlay size={23} style={{ cursor: "pointer" }} title='Play' onClick={handlePlay} />
                                        }

                                        {/* <FaRegCirclePlay /> */}
                                    </div>
                                    <div className=' fill' ><FiTrash2 size={23} style={{ cursor: "pointer" }} onClick={handleRemoveRecording} /></div>
                                </div>
                            </div>
                        )

                    }) : ""}

            </div>
            <div className='text-end pt-3'>
                <button
                    className='demoLink small m-0'
                    onClick={handleUpload}
                    style={audioURL ? {} : { cursor: "not-allowed" }}
                    disabled={audioURL ? false : true}
                >
                    <span>{loaderRecord.record ? <>Creating <i className="fa fa-spinner fa-spin" /></> : "Create Voice"}</span>
                </button>
            </div>

            </div>
        </>
    )
}

export default Recording