import React from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import Navbar from '../CommonComponents/Navs/Navbar'
import SideNav from '../CommonComponents/Navs/SideNav'
import PrivacyEmail from './PrivacyEmail'
import PrivacyGdpr from './PrivacyGdpr'
import Footer from '../CommonComponents/Footer/Footer'

const Privacy = () => {
    return (
        <>
            <TitleBar title="Privacy" />
            <Navbar />

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <PrivacyEmail />
                                        <PrivacyGdpr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Privacy