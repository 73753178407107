import React, { useEffect, useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
// import Alert from '../../Alert';
// import RecordAudioModal from '../../createCourse/RecordAudioModal';
// import UploadComponent from '../../uploadFiles/UploadComponent';
import AiVoice from './AiVoice';
import Recording from './Recording';
import Sonarity from './Sonarity';

import { FiUpload } from "react-icons/fi";
import { BsStars } from "react-icons/bs";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { setTtsData } from '../../../Redux/Actions/VideoActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import TextToSpeech from './TextToSpeech';
import UploadMusic from '../../CommonComponents/UploadMusic';

const VoiceOver = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [active, setActive] = useState({
        sonority: false,
        recording: false
    })

    const addVoiceover = (url, duration) => {

        const audio = {
            src: url,
            duration: +duration <= 0 ? 1 : duration,
            meta: {
                languageId: "",
                text: "",
                translateText: "",
                voiceId: "",
            }
        }
        if (+duration <= 30) {
            setSelectedAudio(url);
            dispatch(setTtsData(audio, selectedSlideIndex, true))
        } else {
            dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
        }

    }

    const handleActive = (type) => {
        if (type === "sonority" && active.sonority === false) {
            setActive({
                ...active,
                sonority: true,

            })
        }
        else if (type === "recording" && active.recording === false) {
            setActive({
                ...active,
                recording: true
            })
        }
    }

    useEffect(() => {
        setSelectedAudio(campaign.slides[selectedSlideIndex].voiceOver.src)
    }, [selectedSlideIndex])

    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-cloning">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="voice-cloning">Voice Cloning</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="sonarity-tab">Sonority</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-audio">Upload Voiceover</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="recording-tab">Recording</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>

                        <Tab.Pane eventKey="voice-cloning">
                            <div className='innertab'>
                                <div className='txt-format'>
                                    <h2>Import your voice</h2>
                                    <p style={{ fontSize: '0.8rem' }}>Import your voice. You can record it or upload a file</p>
                                    <div className='gradient-box mt-3'>
                                        <div className='upload-field'>
                                            <FiUpload />
                                            <span>Import my voice</span>
                                            <input type="file" />
                                        </div>
                                    </div>
                                </div>

                                <div className='txt-format mt-3'>
                                    <h2>Generate Audio</h2>
                                    <p><span style={{ fontSize: '0.8rem' }}>Listen to text with your own voice</span></p>
                                    <div className='gradient-box mt-3'>
                                        <select className='input' name="" id="">
                                            <option value="">Autodetect language</option>
                                        </select>
                                    </div>
                                    <div className='gradient-box mt-3'>
                                        <select className='input' name="" id="">
                                            <option value="">Select Tone</option>
                                        </select>
                                    </div>
                                    <div className='gradient-box mt-3'>
                                        <div className='upload-field'>
                                            <p style={{ fontSize: '0.8rem', lineHeight: '22px' }}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between mt-3'>
                                        <button className='demoLink small m-0'><BsStars /> Clone Voice</button>
                                        <button className='demoLink small m-0'><MdOutlineRecordVoiceOver /> Use Voice</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="voice-tab">
                            <TextToSpeech />
                            {/* <AiVoice
                                slideData={slideData}
                            /> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="sonarity-tab">
                            <Sonarity
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-audio">
                            <UploadMusic
                                type="voiceover"
                                onSelectAudio={addVoiceover}
                                selectedAudio={selectedAudio}
                                setSelectedAudio={setSelectedAudio}
                            />
                            {/* <UploadComponent
                                type="voiceover"
                                tabType="voiceover"
                                selectedSlideIndex={selectedSlideIndex}
                            /> */}
                        </Tab.Pane>

                        <Tab.Pane eventKey="recording-tab">
                            <Recording
                                active={active}
                                onSelectAudio={addVoiceover}
                                selectedAudio={selectedAudio}
                                setSelectedAudio={setSelectedAudio}
                            />
                        </Tab.Pane>


                    </Tab.Content>

                </Tab.Container>
            </div>
        </div>
    )
}

export default VoiceOver