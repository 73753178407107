import React from 'react'
import Navbar from '../CommonComponents/Navs/Navbar'
import TitleBar from '../CommonComponents/TitleBar'
import SideNav from '../CommonComponents/Navs/SideNav'
import Footer from '../CommonComponents/Footer/Footer'
import AccountImage from './AccountImage'
import AccountInfo from './AccountInfo'
import AccountPassword from './AccountPassword'

const MyAccount = () => {
    return (
        <>
            <TitleBar title="My Account" />
            <Navbar />
            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <AccountImage />
                                        <AccountInfo />
                                        <AccountPassword />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default MyAccount