import React from "react";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import { useSelector } from "react-redux";
import queryString from "query-string";
import TitleBar from "../CommonComponents/TitleBar";
import Navbar from "../CommonComponents/Navs/Navbar";
import Footer from "../CommonComponents/Footer/Footer";
import { useLocation } from "react-router";

const Training = () => {
    const location = useLocation()
    const supportData = useSelector(state => state.help.articles);
    const articleId = queryString.parse(location.search).id;

    return (
        <>
            <TitleBar title="Training" />
            <Navbar />

            <section className="siteWrap">
                <div className="tabCont">
                    <div className="row">

                        <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Training;