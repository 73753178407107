import React, { useEffect, useState } from 'react'

import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import videoThumb from '../../images/fav-new.png';
import { Modal } from 'react-bootstrap';
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { Link } from 'react-router';
import Footer from '../CommonComponents/Footer/Footer';
import CreateModal from './Create/CreateModal';
import { useDispatch } from 'react-redux';
import QuizRows from './QuizRows';
import { fetchData } from '../../Redux/Actions/CommonActions';


const Quiz = () => {
    const dispatch = useDispatch()
    const [quiz, setQuiz] = useState([])
    const [loader, setLoader] = useState({ fetch: true })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchQuiz = () => {
        let data = {}
        dispatch(fetchData("fetch-all-quiz", data, setQuiz, loader, setLoader))
    }

    useEffect(() => {
        fetchQuiz()
    }, [])

    return (
        <>
            <TitleBar title="Quiz" />
            <Navbar />
            <section className="siteWrap">
                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Projects"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <a onClick={handleShow} className="demoLink"><BsStars /> Create New Quiz</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="forproject">
                                <div className="row mt-5 mb-5">
                                    {quiz.length > 0 ?
                                        quiz.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <QuizRows
                                                        curElem={curElem}
                                                        quiz={quiz}
                                                        setQuiz={setQuiz}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        !loader.fetch ?
                                            <div className="no-campaign-card col-md-12">
                                                <div className="no-campaign-card-box text-center text-muted mt-3">
                                                    <h4>
                                                        You have not create quiz yet!
                                                    </h4>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-md-12">
                                                <h4 className="text-center load-icon-help">
                                                    <i className="fa fa-spinner fa-spin mr-2" />
                                                </h4>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CreateModal
                show={show}
                handleClose={handleClose}
            />

            <Footer />
        </>
    )
}

export default Quiz;