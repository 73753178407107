import { setAlert } from "./AlertActions"
import { commonAxios } from "../../Global/CommonAxios"

export const onCreateCourse = (data, navigate, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("course-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/create-topic?id=${res.data}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.message, "successs"))
            setLoader({
                ...loader,
                create: false
            })
        })
}

export const onUpdateCourse = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("course-update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/create-chapter?id=${data.courseId}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                update: false
            })
        }).catch(err => {
            setLoader({
                ...loader,
                update: false
            })
            console.log(err)
        })
}

export const onUpdateCourseName = (data) => (dispatch, getState) => {
    commonAxios("course-update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
            }
        }).catch(err => {
            console.log(err)
        })
}

export const onSaveVideoChapter = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("slide-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.buttonType === "generate") {
                    navigate(`/editor?id=${data.chapterId}`)
                } else {
                    dispatch({ type: "ON_SAVE_CHAPTER_SCRIPT", payload: data })
                    dispatch(setAlert(res.msg, "success"))
                }
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
        }).catch((error) => {
            console.log(error)
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
            dispatch(setAlert(error.msg, "danger"))
        })
}



export const onFetchCourse = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("course-fetch", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_COURSE_DATA", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.message, "successs"))
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onGetAiData = (data, setAiSuggestion, loader, setLoader) => (dispatch, getState) => {
    commonAxios("openai-call", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setAiSuggestion(res.data)
                // setLimit(auth.user.topic_limit - data.limit)
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                aiLoader: false,
            })
        }).catch((error) => {
            setLoader({
                ...loader,
                aiLoader: false
            })
            console.log(error)
        })
}

export const onUseAiRecord = (data, setAiSuggestion, index) => (dispatch, getState) => {
    commonAxios("use-ai-record", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setAiSuggestion((prev) => {
                    let updatedArr = [...prev]
                    updatedArr.forEach((curElem) => curElem.isSelected = 0)
                    updatedArr[index].isSelected = 1
                    return updatedArr
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchAiData = (data, setAiSuggestion, functionCall) => (dispatch, getState) => {
    commonAxios("course-ai-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data.length > 0) {
                    setAiSuggestion(res.data)
                } else {
                    if (functionCall) {
                        functionCall()
                    }
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onCreateChapters = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("chapter-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/chapters?id=${data.courseId}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                create: false
            })

        })
}

export const onTranscribeLanguage = (data, fetchChapters, loader, setLoader) => (dispatch, getState) => {
    commonAxios("transcribe", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data.job_status === 1) {
                    fetchChapters()
                    // dispatch(addTranscribeData(res.data, chapterId, fileType))
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                transcribe: false
            })

        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                transcribe: false
            })
        })
}


export const onFetchChapter = (data) => (dispatch, getState) => {
    commonAxios("chapters-fetch", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_COURSE_CHAPTER", payload: { obj: res.data[0], chapterId: data.chapterId, type: data.type } })
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCheckTranscribe = (data, handleSaveTranscribe, setCommon, intCheck) => (dispatch, getState) => {
    commonAxios("check-transcribe", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleSaveTranscribe(res.data)
                clearInterval(intCheck)
                setCommon(prev => {
                    return {
                        ...prev,
                        isEditabel: true,
                        statusText: "Script Generated",
                        status: 8,
                        urlToRedirect: `/write-chapters?id=${data.courseId}&chapId=${data.chapterId}`
                    }
                })
            }
            else {
                if (res.msg === "Transcription failed") {
                    setCommon(prev => {
                        return {
                            ...prev,
                            isEditabel: true,
                            status: 7,
                            statusText: "Transcribing Failed",
                        }
                    })
                    clearInterval(intCheck)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
}
export const onSaveChapter = (data) => (dispatch, getState) => {
    commonAxios("slide-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
            }
            else {
                // dispatch(setAlert(res.msg, "danger"))

            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onCheckSlides = (data, navigate, loader, setLoader, pType) => (dispatch, getState) => {
    commonAxios("slide-fetch", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/editor?id=${data.chapterId}`)
            }
            else {
                if (pType) {
                    navigate(`/review-script?id=${data.chapterId}`)
                } else {
                    navigate(`/write-chapters?id=${data.courseId}&chapId=${data.chapterId}`)
                }
            }
            setLoader({
                ...loader,
                check: false
            })
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                check: false
            })
        })
}

//No Axios Here


export const addTopic = (data) => (dispatch) => {
    dispatch({ type: "SELECT_TOPIC", payload: data })
}

export const onHandleCourse = (name, value) => (dispatch) => {
    dispatch({ type: "HANDLE_COURSE_CHANGE", payload: { name, value } })
}


// export const addTextType = (chapId) => (dispatch) => {
//     dispatch({type : "ADD_TEXT_TYPE"})
// }