import React, { useState } from 'react'
import modalCloseIcon from '../../images/modal-close.png';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { onSubmitUser } from '../../Redux/Actions/PublicQuizActions';
import { useNavigate } from 'react-router';

const StartQuizModal = ({ show, handleClose, quizCustomization, quizId, userId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        age: ""
    })
    const [loader, setLoader] = useState({
        submit: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submit: true
        })
        let obj = { ...userData }
        obj.userId = userId
        obj.quizId = quizId
        dispatch(onSubmitUser(obj, navigate, loader, setLoader))
    }

    return (
        <Modal className="VideoModal" show={show} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec quiz-card  p-4" onSubmit={handleSubmit}>
                    <div className='gradient-box p-0 no-bg ' >
                        <div className='row'>
                            {quizCustomization.participantDetails.firstName ?
                                <div className='col-12 p-3'>
                                    <label>First Name</label>
                                    <input
                                        type='text'
                                        className='input'
                                        name='firstName'
                                        value={userData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div> : ""}
                            {quizCustomization.participantDetails.lastName ?
                                <div className='col-12 p-3'>
                                    <label>Last Name</label>
                                    <input
                                        type='text'
                                        className='input'
                                        name='lastName'
                                        value={userData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div> : ""}
                            {quizCustomization.participantDetails.email ?
                                <div className='col-12 p-3'>
                                    <label>Email</label>
                                    <input
                                        type='email'
                                        className='input'
                                        name='email'
                                        value={userData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div> : ""}
                            {quizCustomization.participantDetails.gender ?
                                <div className='col-12 p-3'>
                                    <label>Gender</label>
                                    <select
                                        className='input'
                                        value={userData.gender}
                                        name='gender'
                                        onChange={handleChange}
                                    >
                                        <option value={""}>Select Gender</option>
                                        <option value={"male"}>Male</option>
                                        <option value={"female"}>Female</option>
                                    </select>
                                </div> : ""}
                            {quizCustomization.participantDetails.age ?
                                <div className='col-12 p-3'>
                                    <label>Age</label>
                                    <input
                                        type='number'
                                        className='input'
                                        name='age'
                                        value={userData.age}
                                        onChange={handleChange}
                                        required
                                    />
                                </div> : ""}
                        </div>
                    </div>
                    <div className='text-right'>
                        <button type="button" className="inpBtn mt-3 create-modal-btn" onClick={handleClose}>Cancel</button>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn ms-2">
                            {loader.create ? <>Submiting <i className="fa fa-spinner fa-spin" /></> : "Submit"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default StartQuizModal