import React, { useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { IoClose } from 'react-icons/io5'
import { MdDragIndicator } from 'react-icons/md'

const YourChapterRows = ({ curElem, index, setFieldArr, provided }) => {
    const [isEdit, setIsEdit] = useState(false)
    const tileRef = useRef(null)

    const handleRemove = () => {
        setFieldArr((draft) => {
            draft.splice(index, 1)
        })
    }

    const handleEdit = () => {
        setIsEdit(true)
        tileRef.current.focus()
    }

    const handleChange = (e) => {
        setFieldArr((draft) => {
            draft[index].name = e.target.value
        })
    }

    return (
        <div
            className="chapterList-single"
            onDoubleClick={handleEdit}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <span className='chapterList-icon'><MdDragIndicator /></span>
            <div className="chapterList-single-left px-3">
                <ContentEditable
                    innerRef={tileRef}
                    html={curElem.name}
                    disabled={!isEdit}
                    onChange={handleChange}
                    onBlur={() => setIsEdit(false)}
                    style={{ fontSize: '0.8rem', color: '#FFFFFF' }}
                    tagName='p'
                />
            </div>
            <span className='chapterList-icon'><IoClose onClick={handleRemove} style={{ cursor: "pointer" }} title='Remove' /></span>
        </div>
    )
}

export default YourChapterRows