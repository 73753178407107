import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import Navbar from '../CommonComponents/Navs/Navbar'
import SideNav from '../CommonComponents/Navs/SideNav'
import CreateAccount from './CreateAccount'
import { fetchAccData } from '../../Redux/Actions/AccountManagements'
import { useDispatch } from 'react-redux'
import Footer from '../CommonComponents/Footer/Footer'
import MemberRows from './MemberRows'
import { themeColor } from '../../Global/Global'

const AccountManagement = () => {
    const dispatch = useDispatch()
    const [teamMember, setTeamMember] = useState([])
    const [clients, setClients] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
    });
    const fetchMembers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
    }

    useEffect(() => {
        fetchMembers()
    }, [])


    return (
        <>
            <TitleBar title="Account Management" />
            <Navbar />

            <section className="siteWrap">
                <div className="siteWrap-in">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>

                        <div className="cont_area">

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <CreateAccount
                                            fetchMembers={fetchMembers}
                                        />
                                        <div className="profileBlock-wrap profileBlock-wrap-last">
                                            <h2 className="infoTitle">Manage Client Accounts</h2>
                                            <div className="profileBlock tableWrap mt-0">
                                                <table className="table table-striped table-dark my-table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email Address</th>
                                                            <th scope="col">Created</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            clients.length > 0 ?
                                                                clients.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <MemberRows
                                                                                type="client"
                                                                                fetchMembers={fetchMembers}
                                                                                curElem={curElem}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        {loader.fetch ?
                                                                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> :
                                                                            <>You do not have client account created yet!</>}
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="profileBlock-wrap profileBlock-wrap-last">
                                            <h2 className="infoTitle">Manage Team Member Accounts</h2>
                                            <div className="profileBlock tableWrap mt-0">
                                                <table className="table table-striped table-dark my-table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email Address</th>
                                                            <th scope="col">Created</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            teamMember.length > 0 ?
                                                                teamMember.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <MemberRows
                                                                                type="team"
                                                                                fetchMembers={fetchMembers}
                                                                                curElem={curElem}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        {loader.fetch ?
                                                                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> :
                                                                            <>You do not have team member account created yet!</>}
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            </section>

            <Footer />
        </>
    )
}

export default AccountManagement