import logoImg from "../../../images/IMG.png";
// import Affiliate from './Affiliate'
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs'
import React, { useEffect, useMemo, useState } from "react";
import sample from "../../../images/sample.png";
import axios from "axios";
// import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
// import { setAlert } from "../../../actions/alert";
// import swal from "sweetalert";
import HoverVideoPlayer from "react-hover-video-player";
import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from "../../../Redux/Actions/VideoActions";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import LogoWatermark from "./LogoWatermark";
import VolumeControl from "./VolumeControl";
// import Alert from "../../Alert";
// import { addSlideDuration, aiVolumeChange, bgVolumeChange, removeLogoWatermark, updateLogoWatermark, updateStatus } from "../../../actions/chapterAction";
// import { commonAxios } from "../../../global/CommonAxios";
// import { fileUpload } from "../../../actions/courseAction";

const Setting = () => {

  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.data)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

  const logo = campaign.logo
  const watermark = campaign.watermark

  const addDuration = (e) => {
    let num = e.target.value
    if (num > 300) {
      dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
      dispatch(addSlideDuration(300))
    } else {
      dispatch(addSlideDuration(num))
    }
  }

  const handleMuteVideoLayer = (data) => {
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    if (data.mute === false) {
      dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
    }
  }

  function hasAudio(video) {
    return video.mozHasAudio ||
      Boolean(video.webkitAudioDecodedByteCount) ||
      Boolean(video.audioTracks && video.audioTracks.length);
  }

  const handleUnMuteVideoLayer = (selectedLayer) => {
    let video = document.querySelector('#checkAudioData');
    video.play()
    video.muted = true
    setTimeout(() => {
      let data = hasAudio(video)
      if (data) {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayer.mute) {
          dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
      } else {
        dispatch(setAlert("This video does not have an audio", "danger"))
      }
      video.pause()
    }, 1000)
  }

  return (
    <>
      {/* <Alert /> */}
      <div className="tabInner">
        <div className="media-block">
          <LogoWatermark
            type={"logo"}
            data={logo}
          />

          <LogoWatermark
            type={"watermark"}
            data={watermark}
          />
          {/* <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title">
                <h2>Add Logo</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="logo">
                    <input
                      type="checkbox"
                      checked={logo.enable}
                      id="logo"
                      onChange={(e) => handleLogoWaterMark(e, "logo")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {!logo.enable ?
                <>
                  <div className="fileUpload mt-4" style={logo.src ? { color: '#fff' } : {}}>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.logoName.length > 20 ? `${name.logoName.slice(0, 20)}...` : name.logoName}
                    <input
                      type="file"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], "logo")}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadLogo ? <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={logo.src ?
                                  logo.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">

                      <select onChange={(e) => logoWaterPos(e.target.value, 'logo')} className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </>
                : ''}
            </div>
          </div>


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title">
                <h2>Add Watermark</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="watermark">
                    <input
                      type="checkbox"
                      id="watermark"
                      checked={waterMark.enable}
                      onChange={(e) => handleLogoWaterMark(e, "watermark")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {!waterMark.enable ?
                <>
                  <div className="fileUpload mt-4" style={waterMark.src !== "" ? { color: '#fff' } : {}}>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.waterMarkName.length > 23 ? `${name.waterMarkName.slice(0, 23)}...` : name.waterMarkName}
                    <input
                      type="file"
                      id="inputGroupFile01"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], 'watermark')}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadWatermark ?
                              <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={waterMark.src ? waterMark.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">
                      <select onChange={(e) => logoWaterPos(e.target.value, 'watermark')} className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </> : ''}
            </div>
          </div> */}


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Duration (Sec)</h2>
              </div>
              <input
                className="inPut"
                type="number"
                name="duration"
                min={1}
                value={selectedSlide.duration}
                onChange={(e) => addDuration(e)}
              />
            </div>
          </div>

          <div className="add-block-single">
            <VolumeControl />
            {/* <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Volume Control</h2>
                <span style={{ color: "#fff" }}>
                  {play ? <BsPauseCircle
                    fontSize={23}
                    onClick={() => handlePause()}
                    className="cursor-pointer"
                  /> :
                    <BsPlayCircle
                      fontSize={23}
                      onClick={() => handlePlay()}
                      className="cursor-pointer"


                    />}
                </span>

              </div>
              <div>
                <h2 className="pb-2">Voiceover <span style={{ float: 'right' }}>{`${selectedSlide.voiceOver.volume}%`}</span></h2>
                <input
                  type="range"
                  min={1}
                  className="slidern cursor-pointer"
                  name="voiceOver"
                  id="myRange"
                  value={selectedSlide.voiceOver.volume}
                  onChange={e => handleVolumeChange(e, 'voiceover')}
                />

                <h2 className="pb-2 mt-4">Music  <span style={{ float: 'right' }}>{`${chapter.audio.volume}%`}</span></h2>
                <input
                  type="range"
                  min={1}
                  className="slidern cursor-pointer"
                  id="myRange"
                  name="music"
                  value={chapter.audio.volume}
                  onChange={e => handleVolumeChange(e, 'music')}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};


export default Setting;
