import React from 'react'


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { FiBold, FiUnderline } from "react-icons/fi";
import { RiItalic, RiStrikethrough2 } from "react-icons/ri";
import { IoChevronDownOutline } from "react-icons/io5";

import { FiUpload } from "react-icons/fi";

function PromoBackground() {
  return (
    <>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className='text-center'>
            <Nav variant="v" className="tabStyleOne">
                <Nav.Item>
                    <Nav.Link eventKey="first">Background Music</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="second">Sound Effects</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    
        <Tab.Content className='st-tabCont'>
        <Tab.Pane eventKey="first">
            <h6>Background Music and Sound Effects:</h6>
            <p className='pt-2' style={{fontSize: '0.85rem'}}>Select a suitable background music and sound effect or use AI to generate appropriate background tracks based on the course subject </p>

            <div className='file-up mt-3'>
                <FiUpload />
                <p className='pt-2'>Import Audio File</p>
                <p>Suggested formats: mp3, wav, flac, ocg </p>
                <input type="file" />
            </div>

            <h6 className='pt-4'>Uploaded Background Music</h6>
            <div className='musicBox mt-3'>
                <ul>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                </ul>
            </div>

            <h6 className='pt-4'>AI-Generated Background Music</h6>
            <p className='pt-2' style={{fontSize: '0.85rem'}}>Generate background music based on course subject</p>

            <div className='musicBox mt-3'>
                <ul>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                    <li>
                        <div className='musicBox-single'>
                            <div className='musicBox-in'><img src={require('../../../images/editor/music-4.svg').default} /></div>
                            <p>Futuristic</p>
                        </div>
                    </li>
                </ul>
            </div>
        </Tab.Pane>

        <Tab.Pane eventKey="second">
            <h6>Sound Effects</h6>
        </Tab.Pane>

        </Tab.Content>

    </Tab.Container>
    </>
  )
}

export default PromoBackground