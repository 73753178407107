import React, { useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import videoThumb from '../../images/fav-new.png';

import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { Link } from 'react-router';
import Footer from '../CommonComponents/Footer/Footer';

import Dropdown from 'react-bootstrap/Dropdown';
import { PiDotsThreeCircle } from "react-icons/pi";


const LandingPage = () => {
    

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return (
        <>
            <TitleBar title="Landing Page" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                    <div className="project-input-in">
                                        <span><SearchNormal /></span>
                                        <input
                                        type="text"
                                        className="inp"
                                        placeholder="Search Projects"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button onClick={handleShow} className="demoLink"><BsStars /> Generate Landing Page</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="forproject">
                                <div className="row mt-5 mb-5">

                                    <div className="col-xl-3 col-md-6 col-sm-12">
                                        <div className="videoProject-single">
                                            <div className="videoProject-single-top">
                                                <div className="video-card-wrapper-1 dashboard-card-bg">
                                                    <div className="video-card-content">
                                                       <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                                                    </div>
                                                    <span className="qz-tag">Draft</span>
                                                </div>
                                                <div className="vidHover">
                                                   <div><p>2024-11-22 05:38:17</p></div>
                                                   <div className="vidiconAll">
                                                       <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                        <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="videoProject-single-bottom">
                                                <div>
                                                    <h6>Landing Page Demo</h6>
                                                    <p>Date Created: 12-01-24</p>
                                                </div>
                                            </div>

                                            <Dropdown className='pro-dropdown'>
                                                <Dropdown.Toggle variant="v" id="dropdown-basic">
                                                   <PiDotsThreeCircle />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/edit.svg').default} alt="" /></span>
                                                        Edit Course
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/pdf.svg').default} alt="" /></span>
                                                        Create PDF
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/quiz.svg').default} alt="" /></span>
                                                        Generate Quiz
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/landing-page.svg').default} alt="" /></span>
                                                        Create Landing Page
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/delete.svg').default} alt="" /></span>
                                                        Delete
                                                    </span>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>


                                    <div className="col-xl-3 col-md-6 col-sm-12">
                                        <div className="videoProject-single">
                                            <div className="videoProject-single-top">
                                                <div className="video-card-wrapper-1 dashboard-card-bg">
                                                    <div className="video-card-content">
                                                       <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                                                    </div>
                                                    <span className="qz-tag active">Active</span>
                                                </div>
                                                <div className="vidHover">
                                                   <div><p>2024-11-22 05:38:17</p></div>
                                                   <div className="vidiconAll">
                                                       <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                        <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="videoProject-single-bottom">
                                                <div>
                                                    <h6>Landing Page Demo</h6>
                                                    <p>Date Created: 12-01-24</p>
                                                </div>
                                            </div>
                                            <Dropdown className='pro-dropdown'>
                                                <Dropdown.Toggle variant="v" id="dropdown-basic">
                                                   <PiDotsThreeCircle />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/edit.svg').default} alt="" /></span>
                                                        Edit Course
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/pdf.svg').default} alt="" /></span>
                                                        Create PDF
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/quiz.svg').default} alt="" /></span>
                                                        Generate Quiz
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/landing-page.svg').default} alt="" /></span>
                                                        Create Landing Page
                                                    </span>
                                                    <span className='dropdownLink'>
                                                        <span className='dropdownLink-icon'><img src={require('../../images/dashboard/delete.svg').default} alt="" /></span>
                                                        Delete
                                                    </span>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>

                
            </section>
            
            <Footer />


            <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <form className="formSec text-center p-4">
                        <div className="inpField">
                            <h5 className='pb-4'>Enter  the Project Name</h5>
                            <input
                                type="text"
                                className="inpField-inp"
                                placeholder="Project Name Here..."
                                required
                            />
                        </div>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn"><BsStars /> Next</button>
                    </form>
                </Modal.Body>
           </Modal>

           <Modal className="VideoModal smaller white" show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <div onClick={handleClose2} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className='text-center pt-4 pb-4'>
                       <img src={require('../../images/dashboard/clap.png')} alt="" />
                       <h5 className='pt-3'>Your course on Digital Marketing <br /> Basics is ready!</h5>
                       <div className='d-flex flex-column gap-3 mt-4'>
                        <div><button className='demoLink m-0' style={{maxWidth: 255}}>Generate Landing Page</button></div>
                        <div><button className='demoLink grey m-0' style={{maxWidth: 255}}>Preview Course</button></div>
                       </div>
                    </div>
                </Modal.Body>
           </Modal>

        </>
    )
}

export default LandingPage;