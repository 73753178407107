import React, { useState } from 'react'
import EditMemberModal from './EditMemberModal'
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { onDeleteAcc } from '../../Redux/Actions/AccountManagements'
import { Trash } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import CommonAlert from '../CommonComponents/CommonAlert'

const MemberRows = ({ curElem, type, fetchMembers }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
        icon: <Trash />
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
            id: curElem.id,
            name: curElem.name
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
            name: ""
        })
    }


    const performDelete = () => {
        let data = {
            client_id: sweet.id,
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteAcc(data, fetchMembers, setSweet));
    }

    return (
        <tr>
            <th scope="row"><h6 className="text-wrapper m-0">{curElem.name}</h6></th>
            <td><h6 className="text-wrapper m-0">{curElem.email}</h6></td>
            <td>{curElem.created}</td>
            <td className="expand-button my_expand_btn">
                <a
                    className="action-icon delete-icon remove mb-1 mt-md-1 cursor-pointer"
                    title="Edit"
                    aria-expanded="true"
                    onClick={handleShow}
                >
                    <i className="fas fa-edit"></i>
                </a>
                <a
                    className="action-icon delete-icon remove mb-1 pl-xl-2 mt-md-1"
                    style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                    onClick={onDelete}
                >
                    <i className="fas fa-trash-alt"></i>
                </a>
            </td>

            <EditMemberModal
                curElem={curElem}
                show={show}
                type={type}
                fetchMembers={fetchMembers}
                handleClose={handleClose}
            />

            <CommonAlert
                show={sweet.enable}
                message={`Are you sure? Do you want to delete this ${type} ${type === "team" ? "member" : ""} account.`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />

        </tr>
    )
}

export default MemberRows