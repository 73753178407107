import React, { useEffect, useState } from 'react'
import CommonAlert from '../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData, onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { Link, useNavigate } from 'react-router'
import videoThumb from "../../images/fav-new.png"

const VideoCard = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [courses, setCourses] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
        icon: <Trash />
    })



    const handleRedirect = (curData) => {
        if (!curData.topicName) {
            navigate(`/create-topic?id=${curData.id}`)
        } else {
            navigate(`/chapters?id=${curData.id}`)
        }
    }

    const onDelete = (id, name) => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
            id: id,
            name: name
        })
    }

    const onCancelDelete = () => {

        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
            name: ""
        })
    }




    const performDelete = () => {
        let data = {
            id: sweet.id,
            type: "courses"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, false, false, setSweet, fetchCourses))
    }

    const fetchCourses = () => {
        let data = {}
        dispatch(fetchData("courses-list", data, setCourses, loader, setLoader, props.showRecord))
    }

    useEffect(() => {
        fetchCourses()
    }, [])
    return (
        <>
            <div className="row mt-5 mb-5">
                {courses.length > 0 ?
                    courses.filter((item) => {
                        if (props.searchKey && !props.showRecord) {
                            return item.projectName.toLowerCase().includes(props.searchKey.toLowerCase())
                        }
                        else {
                            return item
                        }
                    }).map((curElem) => {
                        return (
                            <div className="col-xl-3 col-md-6 col-sm-12" key={curElem.id}>
                                <div className="videoProject-single">
                                    <div className="videoProject-single-top ">
                                        <div className="video-card-wrapper-1 dashboard-card-bg">
                                            {curElem.thumbnail === "" ?
                                                <div className="video-card-content ">
                                                    <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                                                </div> :
                                                <div className="video-card-content-2 ">
                                                    <img className="mb-3  img-fluid" src={curElem.thumbnail} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                                </div>
                                            }
                                        </div>

                                        <div className="vidHover">
                                            <div><p>{curElem.created}</p></div>
                                            <div className="vidiconAll">

                                                <div className="vidHover-single">
                                                    <a onClick={() => handleRedirect(curElem)}>
                                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                        <span>Edit</span>
                                                    </a>
                                                </div>
                                                {+auth.user.isClientAccount !== 1 ?
                                                    <div className="vidHover-single cursor-pointer" onClick={() => onDelete(curElem.courseId, curElem.projectName)}>
                                                        <a>
                                                            <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="videoProject-single-bottom">
                                        <span>{curElem.projectName}</span>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                    : !loader.fetch ? <div className="col-md-12">
                        <div className="text-center text-muted ">
                            <h4>You do not have any course created in your account</h4>
                        </div>
                    </div> : ''}

                {
                    loader.fetch ?
                        <div className="col-md-12 text-center" >
                            <i className="fa fa-spinner fa-spin" style={{ color: "#6d02f8", fontSize: "34px" }} />
                        </div> : ''
                }
            </div>

            <CommonAlert
                show={sweet.enable}
                message={`Deleting the course "${sweet.name}" will delete all chapters of it`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </>
    )
}

export default VideoCard