import React, { useEffect, useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import videoThumb from '../../images/fav-new.png';

import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { Link, useNavigate } from 'react-router';
import Footer from '../CommonComponents/Footer/Footer';
import CreateModal from './Create/CreateModal';
import ChooseTypeModal from './Create/ChooseTypeModal';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import PromoVideoCard from './PromoVideoCard';
import ChapterCard from '../Courses/ChapterCard';


const PromoVideo = () => {
    const dispatch = useDispatch()

    const [promoVideo, setPromoVideo] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const fetchPromoVideos = () => {
        dispatch(fetchData("fetch-all-promo-video", {}, setPromoVideo, loader, setLoader))
    }

    useEffect(() => {
        fetchPromoVideos()
    }, [])

    return (
        <>
            <TitleBar title="Promo Video" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Projects"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button onClick={handleShow} className="demoLink"><BsStars /> Create Promo Video</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="forproject">
                                <div className="row mt-5 mb-5">
                                    {promoVideo.length > 0 ?
                                        promoVideo.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="col-xl-3 col-md-6 col-12" key={index}>
                                                        {/* <PromoVideoCard
                                                        curElem={curElem}
                                                        promoVideo={promoVideo}
                                                        setPromoVideo={setPromoVideo}
                                                    /> */}
                                                        <ChapterCard
                                                            curElem={curElem}
                                                            promoVideo={promoVideo}
                                                            setPromoVideo={setPromoVideo}
                                                            type={"promo"}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className='col-md-12 text-center'>
                                            <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                {loader.fetch ?
                                                    <i className="fa fa-spinner fa-spin" /> :
                                                    <h4 >
                                                        No promo video created yet!
                                                    </h4>
                                                }
                                            </div>
                                        </div>

                                    }



                                    {/* <div className="col-xl-3 col-md-6 col-sm-12">
                                        <div className="videoProject-single">
                                            <div className="videoProject-single-top">
                                                <div className="video-card-wrapper-1 dashboard-card-bg">
                                                    <div className="video-card-content">
                                                        <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                                                    </div>
                                                    <span className="qz-tag active">Active</span>
                                                </div>
                                                <div className="vidHover">
                                                    <div><p>2024-11-22 05:38:17</p></div>
                                                    <div className="vidiconAll">
                                                        <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                        <div className="vidHover-single">
                                                            <Link to="/">
                                                                <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                                <span>Edit</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="videoProject-single-bottom">
                                                <div>
                                                    <h6>Promo Video Demo</h6>
                                                    <p>Date Created: 12-01-24</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <Footer />

            <CreateModal
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default PromoVideo;