import React, { useState } from 'react'
import { MdDragIndicator } from 'react-icons/md'
import { BiEditAlt, BiPlus } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import ContentEditable from 'react-contenteditable'
import { onChangeOptionText, onChangeQuestionText, onReorderOptions } from '../../../Redux/Actions/QuizActions'
import { useDispatch } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { AddSquare, Trash } from 'iconsax-react'
import AddQuestionModal from './AddQuestionModal'

const QuestionsBox = ({ curElem, length, index, setActiveIndex, activeIndex, view, deleteQuestion }) => {
    const dispatch = useDispatch()
    const quiz = useSelector(state => state.quiz.data)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    // const [isEdit, setIsEdit] = useState(false)
    // const [isEditOption, setIsEditOption] = useState({
    //     enable: false,
    //     ind: 0
    // })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        deleteQuestion(index)
    }

    const onPrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(prev => prev - 1)
        }
    }

    const onNext = () => {
        if (activeIndex < length - 1) {
            setActiveIndex(prev => prev + 1)
        }
    }

    // const handleChange = (e) => {
    //     dispatch(onChangeQuestionText(e.target.value, activeIndex))
    // }

    // const handleEditOption = (opIndex) => {
    //     setIsEditOption({
    //         ...isEditOption,
    //         enable: true,
    //         ind: opIndex
    //     })
    // }

    // const handleChangeOptionText = (e) => {
    //     dispatch(onChangeOptionText(e.target.value, activeIndex, isEditOption.ind))
    // }



    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(curElem.options, result.source.index, result.destination.index);
        dispatch(onReorderOptions(items, activeIndex))
    }


    return (
        <div className={`quiz-prev-outer ${view === "mobile" ? "" : "large"}`}>
            <div className={`quiz-prev-wrap ${view === "mobile" ? "" : "large"}`}>
                <div className='gradient-box'>
                    <div className='quiz-prev-in'>
                        <div className='text-right'>
                            <Trash size={20} title='Delete Question' cursor={"pointer"} onClick={onDelete} />
                            {length < +quiz.quizCustomization.noOfQuestion ?
                                <AddSquare size={20} title='Add Question' cursor={"pointer"} onClick={handleShow} />
                                : ""}
                        </div>
                        <div className='quiz-prev-box'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>Question {index + 1}/{length}</span>
                                <span>{quiz.quizCustomization.timeLimit} : 00</span>
                            </div>
                            <div className='edit-area mt-3'>

                                {/* <ContentEditable
                                    html={curElem.question}
                                    tagName='p'
                                    disabled={!isEdit}
                                    onChange={handleChange}
                                /> */}
                                <p>{curElem.question}</p>
                            </div>
                        </div>

                        {curElem.options && curElem.options.length > 0 ?
                            <>
                                {/* <p style={{ fontSize: '0.8rem' }} className='text-center mt-3'>Double Click Text to Edit</p> */}
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className='selected-ans'
                                            >
                                                {
                                                    curElem.options.map((curData, ind) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Draggable key={ind} draggableId={`id_${ind}`} index={ind}>
                                                                    {(provided) => (
                                                                        <div
                                                                            key={ind}
                                                                            className={`selected-ans-single ${curElem.answer === curData ? "active" : ""}`}
                                                                            // onDoubleClick={() => handleEditOption(ind)}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {curData}
                                                                            {/* <ContentEditable
                                                                                html={curData}
                                                                                tagName='span'
                                                                                disabled={isEditOption.ind === ind ? !isEditOption.enable : true}
                                                                                onChange={handleChangeOptionText}
                                                                            /> */}
                                                                            <MdDragIndicator />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </> : ""}
                        <div className='row pt-5'>
                            <div className='col-6'>
                                <button className='qz-button' onClick={onPrev}>&lt;&lt; Prev</button>
                            </div>
                            <div className='col-6'>
                                <button className='qz-button blue' onClick={onNext}>Next &gt;&gt;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='quiz-prev-nav' onClick={onPrev}><img src={require('../../../images/quiz/nav-prev.svg').default} alt='' /></div>
            <div className='quiz-prev-nav alt' onClick={onNext}><img src={require('../../../images/quiz/nav-next.svg').default} alt='' /></div>
            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this question?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />

            <AddQuestionModal
                show={show}
                handleClose={handleClose}
            />
        </div>
    )
}

export default QuestionsBox