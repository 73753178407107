import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { fetchData, onFetchPlatforms } from '../../Redux/Actions/CommonActions';
import { onFetchPromoVideo, onGeneratePromoScript } from '../../Redux/Actions/VideoActions';
import Platform from '../CommonComponents/Platform';
import Swal from 'sweetalert2';
import { themeColor } from '../../Global/Global';


const ChapterSummaries = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = queryString.parse(location.search)

    const [loader, setLoader] = useState({
        fetch: true,
        generate: false
    })
    const [courseLoader, setCourseLoader] = useState({
        fetch: true
    })
    const [courses, setCourses] = useState([])
    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [platform, setPlatform] = useState(false)
    const [promoData, setPromoData] = useState(false)

    const [state, setState] = useState({
        topicName: "",
        country: "United States",
        dimension: "16x9",
        language: "English",
        languageCode: "en-US",
        style: "engaging"
    })

    const handleChange = (e) => {
        let { name, value } = e.target
        if (promoData.sourceType === "course") {
            setPromoData({
                ...promoData,
                cid: e.target.value
            })
        } else {
            if (name === "language") {
                const lang = language.find(({ name }) => name === value)
                if (+lang.polly_support === 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Warning...',
                        text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                        confirmButtonColor: themeColor
                    })
                }
            }
            if (name === "dimension") {
                value = value === "1920x1080" ? "16x9" : "9x16"
            }
            setState({
                ...state,
                [name]: value
            })
        }
    }


    const handleSubmit = () => {
        let obj = {
            ...promoData
        }
        if (obj.sourceType === "custom") {
            obj.customData = { ...state }
            let count = country.find(({ country_name }) => country_name === state.country)
            let lang = language.find(({ name }) => name === state.language)
            if (count && lang) {
                obj.customData.languageCode = `${lang.code}-${count.code}`
            }
        }
        setLoader({
            ...loader,
            generate: true
        })
        dispatch(onGeneratePromoScript(obj, navigate, loader, setLoader))
    }


    const fetchVideo = () => {
        let data = { id }
        dispatch(onFetchPromoVideo(data, setPromoData, loader, setLoader))
    }

    const fetchCourses = () => {
        let data = {}
        dispatch(fetchData("fetch-quiz-courses", data, setCourses, courseLoader, setCourseLoader))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchPlateform = () => {
        dispatch(onFetchPlatforms(setPlatform))
    }
    const fetchCountry = () => {
        let data = {}
        dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    }


    useEffect(() => {
        if (id) {
            fetchVideo()
        }
    }, [id])

    useEffect(() => {
        if (promoData.sourceType === "course") {
            fetchCourses()
        }
        if (promoData.sourceType === "custom") {
            fetchLanguage()
            fetchPlateform()
            fetchCountry()
        }
    }, [promoData.sourceType])


    return (
        loader.fetch ?
            < div className="loader-sec" >
                <div className="loader">
                </div>
            </div > :
            <>
                <TitleBar title="Quiz" />
                <Navbar />
                <section className="siteWrap">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className="col-auto">
                                        <h2 className='pageTitle-2'>{promoData.name}</h2>
                                    </div>
                                    <div className="col-auto">
                                        <div className="projectTitle-right">
                                            <button className="demoLink grey" onClick={() => navigate(-1)}>Back</button>
                                            <button className="demoLink" onClick={handleSubmit}>Continue {loader.generate ? <i className="fa fa-spinner fa-spin" /> : ""}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className='quiz-wrap'>
                                    <h2 className='text-center gradientTxt'><span>Provide Input from Course Materials</span></h2>
                                    <p className='text-center pt-3'>Choose Course Materials like Lecture Videos, Images,  or Chapter Summaries</p>

                                    <div className='quiz-card mt-5'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='gradient-box p-0 no-bg'>
                                                    {promoData.sourceType === "course" ?
                                                        <>
                                                            <div className='gradient-box-head'>
                                                                <p style={{ color: '#FFF', textTransform: "capitalize" }}>All Your Courses</p>
                                                                <p style={{ fontSize: '13px' }}>Select The <span className='text-capitalize'>Course</span> you would like AI to Identify key highlights from</p>
                                                            </div>
                                                            <div className='gradient-box-in'>
                                                                <div className='question-type'>
                                                                    <ul>
                                                                        {courses.length > 0 ?
                                                                            courses.map((curElem, index) => {
                                                                                return (
                                                                                    <li key={index}>
                                                                                        <div className='question-type-single'>
                                                                                            <div className='d-flex'>
                                                                                                <div className='col-auto'>
                                                                                                    <div className='radio-style'></div>
                                                                                                </div>
                                                                                                <div className='col'>
                                                                                                    <h6>{curElem.projectName}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input
                                                                                                type="radio"
                                                                                                name='cid'
                                                                                                checked={+curElem.id === +promoData.cid}
                                                                                                value={curElem.id}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                        </div>
                                                                                    </li>)
                                                                            }) :
                                                                            <li className='text-center'>
                                                                                {courseLoader.fetch ?
                                                                                    <i className="fa fa-spinner fa-spin" />
                                                                                    : "You do not have courses created!"}
                                                                            </li>

                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className='row'>
                                                            <div className='col-12 p-3'>
                                                                <label>Topic Name</label>
                                                                <input
                                                                    type='text'
                                                                    className='input'
                                                                    name='topicName'
                                                                    value={state.topicName}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-6 p-3'>
                                                                <label>Country</label>
                                                                <select
                                                                    className='input'
                                                                    name='country'
                                                                    value={state.country}
                                                                    onChange={handleChange}
                                                                    required
                                                                >
                                                                    <option value={""}>Select Country</option>
                                                                    {country.length > 0 ?
                                                                        country.map((curElem, index) => {
                                                                            return (
                                                                                <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                                                            )
                                                                        })
                                                                        : ""}
                                                                </select>

                                                            </div>
                                                            <div className='col-6 p-3'>
                                                                <label>Language</label>
                                                                <select
                                                                    className='input'
                                                                    name='language'
                                                                    value={state.language}
                                                                    onChange={handleChange}
                                                                >
                                                                    {language.length > 0 ?
                                                                        language.map((curElem, index) => {
                                                                            return (
                                                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                                                            )
                                                                        })
                                                                        : ""}
                                                                </select>

                                                            </div>
                                                            <div className='col-6 p-3'>
                                                                <label>Plateform</label>
                                                                <select
                                                                    className='input'
                                                                    name='dimension'
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value={"1920x1080"}>{"Standard YouTube Videos"}</option>
                                                                    {platform ?
                                                                        <>
                                                                            <Platform
                                                                                data={platform.Facebook}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Instagram}
                                                                            />
                                                                            <Platform
                                                                                data={platform.LinkedIn}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Pinterest}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Twitter}
                                                                            />

                                                                        </>
                                                                        : ""}
                                                                </select>

                                                            </div>
                                                            <div className='col-6 p-3'>
                                                                <label>Style</label>
                                                                <select
                                                                    className='input'
                                                                    onChange={handleChange}
                                                                    name='style'
                                                                    value={state.style}
                                                                >
                                                                    <option value={""}>Select Style</option>
                                                                    <option value={"controversial"}>Controversial</option>
                                                                    <option value={"engaging"}>Engaging</option>
                                                                    <option value={"fun"}>Fun</option>
                                                                    <option value={"informative"}>Informative</option>
                                                                    <option value={"news"}>News</option>
                                                                    <option value={"promo"}>Promo</option>
                                                                    <option value={"Product review"}>Product review</option>
                                                                    <option value={"VSL"}>VSL</option>

                                                                </select>

                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </section>

                <Footer />

            </>
    )
}

export default ChapterSummaries;