import React, { useEffect, useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
import Music from './Music';
import UploadMusic from '../../CommonComponents/UploadMusic';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addBgMusic } from '../../../Redux/Actions/VideoActions';

const Audio = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.data)
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

  const [selectedAudio, setSelectedAudio] = useState(false)

  const addMusic = (url, duration) => {
    dispatch(addBgMusic(url, selectedSlideIndex, duration))
  }

  useEffect(() => {
    setSelectedAudio(campaign.audio.source)
  }, [campaign.audio.source])


  return (
    <div className="tabInner">
      <div className="media-block">
        <Tab.Container id="left-tabs-example-2" defaultActiveKey="music-tab">
          <Nav variant="pills" className="inTabNav full">
            <Nav.Item>
              <Nav.Link eventKey="music-tab">Music</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="upload-audio">Upload Music</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>

            <Tab.Pane eventKey="music-tab">
              <Music
                onSelectAudio={addMusic}
                selectedAudio={selectedAudio}
                setSelectedAudio={setSelectedAudio}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="upload-audio">
              <UploadMusic
                type="music"
                onSelectAudio={addMusic}
                selectedAudio={selectedAudio}
                setSelectedAudio={setSelectedAudio}
              />
              {/* <UploadComponent
                type="music"
                tabType="music"
              /> */}
            </Tab.Pane>

          </Tab.Content>

        </Tab.Container>
      </div>
    </div>
  );
};

export default Audio;
