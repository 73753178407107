import React, { useEffect, useState } from 'react'
import Footer from '../../CommonComponents/Footer/Footer'
import { IoClose, IoReloadOutline, IoReloadSharp } from 'react-icons/io5'
import SideNav from '../../CommonComponents/Navs/SideNav'
import Navbar from '../../CommonComponents/Navs/Navbar'
import TitleBar from '../../CommonComponents/TitleBar'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import CourseHeader from './CourseHeader'
import queryString from 'query-string'
import { addTopic, onFetchAiData, onFetchCourse, onGetAiData, onHandleCourse, onUpdateCourse, onUseAiRecord } from '../../../Redux/Actions/CourseActions'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import TopicsRows from './TopicsRows'
import { BsStars } from "react-icons/bs";

const CreateTopic = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const course = useSelector(state => state.course.data)

    const [type, setType] = useState("key")
    const [aiSuggestion, setAiSuggestion] = useState([])
    const [loader, setLoader] = useState({
        fetch: true,
        search: false,
        update: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onHandleCourse(name, value))
    }

    const handleType = (ty) => {
        setType(ty)
    }

    const handleSearch = () => {
        let data = {
            text: course.topicName,
            type: "topic",
            limit: 0,
            courseId: id
        }
        setLoader({
            ...loader,
            search: true
        })
        dispatch(onGetAiData(data, setAiSuggestion, loader, setLoader))
    }



    // old Code

    // const handleUse = () => {
    //     let data = {
    //         courseId: id,
    //         projectName: course.projectName,
    //         topicName: course.topicName
    //     }
    //     setLoader({
    //         ...loader,
    //         update: true
    //     })
    //     dispatch(onUpdateCourse(data, navigate, loader, setLoader))
    // }

    // const getAiData = () => {
    //     if (course.topicName) {
    //         let data = {
    //             text: course.topicName,
    //             type: "topic",
    //             limit: 0,
    //             courseId: id
    //         }
    //         setLoader({
    //             ...loader,
    //             aiLoader: true
    //         })
    //         dispatch(onGetAiData(data, setAiSuggestion, loader, setLoader))
    //     } else {
    //         dispatch(setAlert("Please enter a keyword to continue!", "danger"))
    //     }
    // }


    // const handleUse = (str, index, tId, status) => {
    //     dispatch(onHandleCourse("topicName", str))
    //     let data = {
    //         courseId: id,
    //         id: tId,
    //         type: "topic",
    //         isSelected: status === "0" ? "1" : "0"
    //     }
    //     dispatch(onUseAiRecord(data, setAiSuggestion, index))
    // }

    const fetchAiData = () => {
        let data = {
            courseId: id,
            type: "topic"
        }
        dispatch(onFetchAiData(data, setAiSuggestion))
    }

    const fetchCourse = () => {
        let data = {
            courseId: id
        }
        dispatch(onFetchCourse(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchCourse()
            fetchAiData()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Create Course" />
                <Navbar />
                <section className="siteWrap">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>

                        <div className="cont_area" >
                            <div className="projectTitle-wrap">
                                <CourseHeader
                                    courseName={course.projectName}
                                    courseId={course.id}
                                    topicName={course.topicName}
                                />
                                <div className="projectTitle-right">
                                    {/* <button className="demoLink"><BsStars /> Start</button> */}
                                </div>
                            </div>

                            <div className="create-course-mid alt">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 text-center" >
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    <h2 className="text-center gradientTxt mb-3"><span>Course Topic Research</span></h2>
                                                </div>

                                                <div>
                                                    <div className="research-btn-group">
                                                        <button
                                                            className={`demoLink m-0 ${type === "key" ? "act" : ""}`}
                                                            onClick={() => handleType("key")}
                                                        >
                                                            Enter Keyword
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`demoLink m-0 ${type === "ai" ? "act" : ""}`}
                                                            onClick={() => handleType("ai")}
                                                        >
                                                            <BsStars /> "Use AI Recommendation"
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="topic-key">
                                                    <input
                                                        className="courseInp"
                                                        placeholder="Enter The Keyword"
                                                        required
                                                        name="topicName"
                                                        value={course.topicName}
                                                        maxLength={100}
                                                        onChange={handleChange}
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="demoLink"
                                                        style={course.topicName ? {} : { background: "gray", cursor: "not-allowed" }}
                                                        disabled={course.topicName ? false : true}
                                                        onClick={handleSearch}
                                                    >
                                                        <BsStars /> Search {loader.search ? <i className="fa fa-spinner fa-spin" /> : ""}
                                                    </button>
                                                </div>
                                                <p className="pt-4">Enter the Keywords for your course research and click on “Next” to begin</p>
                                            </div>
                                            {aiSuggestion.length > 0 ?
                                                <div className="recomnd mt-4">
                                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                                        <h5 className="gradientTxt"><span>Recommended  Topics</span></h5>
                                                        {/* <button className="simple-btn"><IoReloadOutline /> Load More Recommendaitons</button> */}
                                                    </div>
                                                    <ul>
                                                        {aiSuggestion.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <TopicsRows
                                                                        curElem={curElem}
                                                                        course={course}
                                                                        aiSuggestion={aiSuggestion}
                                                                        setAiSuggestion={setAiSuggestion}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
                <Footer />


            </>
    )
}

export default CreateTopic