import React from 'react'

const ConnectModal = ({ type, onFormSubmit, onInputChange, loader }) => {
    return (
        <div className="modal-body">
            <form className="add-account-box mt-2" method="post" onSubmit={(e) => onFormSubmit(e)} >
                {type === "elevenlabs" ?
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <small className="form-text text-muted">Enter Name</small>
                                    <input type="text" className="form-control"
                                        placeholder="Enter Name" required name="name"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <small className="form-text text-muted">Enter API Key</small>
                                    <input type="text" className="form-control"
                                        placeholder="Enter API Key" required name="apiKey"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                <div className="add-account-save-btn">
                                    <button type="submit" className="btn-change7" disabled={loader.submit}>
                                        Connect {loader.submit ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </> : type === "autoresponder" ?
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="form-label">Enter Name</label>
                                    <input
                                        type="text"
                                        className="form-control mt-1 py-3"
                                        placeholder="Enter Name"
                                        required
                                        style={{ background: "#1c1c1c", color: "#fff" }}
                                        name="name"
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label className="form-label">Opt-in Code</label>
                                    <textarea
                                        type="text"
                                        className="form-control mt-1"
                                        placeholder="Enter Code Here"
                                        style={{ background: "#1c1c1c", color: "#fff" }}
                                        required
                                        name="code"
                                        rows={6}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                    <div className="add-account-save-btn">
                                        <button type="submit" className="btn-change7" disabled={loader.submit}>
                                            Connect {loader.submit ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <small className="form-text text-muted">Email Address</small>
                                        <input type="email" className="form-control" placeholder="Enter Email"
                                            required name="email"
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <small className="form-text text-muted">Password</small>
                                        <input type="password" className="form-control"
                                            placeholder="********" required name="password"
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                    <div className="add-account-save-btn">
                                        <button type="submit" className="btn-change7" disabled={loader.submit}>
                                            Connect {loader.submit ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>}
            </form>
        </div>
    )
}

export default ConnectModal