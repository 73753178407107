import React, { useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";

import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';
import { useNavigate } from 'react-router';


const Participants = () => {
    const navigate = useNavigate()
    return (
        <>
            <TitleBar title="Quiz" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Projects"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink"><BsStars /> Create Quiz</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <div className='participants-card'>
                                    <div className="row">
                                        <div className="col">
                                            <h2 className='pageTitle gradientTxt'><span>Our Demo Quiz</span></h2>
                                            <p><strong>Date Created: 12-01-24</strong></p>
                                        </div>
                                        <div className="col-auto">
                                            <button className='demoLink m-0'>View Aggregated Responses</button>
                                        </div>
                                    </div>

                                    <div className="row gap-4 pt-5" style={{ color: '#FFF' }}>
                                        <div className="col-auto">
                                            <p>Date of Launch</p>
                                            <h5 className='pt-2'>Thu, Feb 24</h5>
                                        </div>
                                        <div className="col-auto">
                                            <p>Quiz Duration</p>
                                            <h5 className='pt-2'>00:30:25</h5>
                                        </div>
                                        <div className="col-auto">
                                            <p>Number  of Participants</p>
                                            <h5 className='pt-2'>23</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4 participants-card'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Participant List</h5>
                                        <button className='demoLink flat m-0' onClick={() => navigate('/review-participants-name')}>View Selected</button>
                                    </div>

                                    <div className='table-responsive mt-4'>
                                        <table className='table theme-table'>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Age</th>
                                                <th>Gender</th>
                                                <th>Score</th>
                                                <th>Completion Time</th>
                                                <th>Action</th>
                                                <th>
                                                    <div className='style-checkbox'>
                                                        <input type="checkbox" />
                                                        <span className='checkbox-select'></span>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className='userImg'><img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <h6>Ade Randall</h6>
                                                            <p>rhavard@mail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>31</td>
                                                <td>Male</td>
                                                <td>92%</td>
                                                <td>00:23:22</td>
                                                <td><button className='demoLink flat m-0'>View</button></td>
                                                <td>
                                                    <div className='style-checkbox'>
                                                        <input type="checkbox" />
                                                        <span className='checkbox-select'></span>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className='userImg'><img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <h6>Ade Randall</h6>
                                                            <p>rhavard@mail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>31</td>
                                                <td>Male</td>
                                                <td>92%</td>
                                                <td>00:23:22</td>
                                                <td><button className='demoLink flat m-0'>View</button></td>
                                                <td>
                                                    <div className='style-checkbox'>
                                                        <input type="checkbox" />
                                                        <span className='checkbox-select'></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-between mt-2'>
                                        <div className='table-nav'>
                                            <ul>
                                                <li className='nxt'><MdOutlineChevronLeft /></li>
                                                <li className='active'>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li className='nxt'><MdOutlineChevronRight /></li>
                                            </ul>
                                        </div>
                                        <div className='d-flex align-items-center gap-3'>
                                            <span style={{ fontSize: '0.8rem' }}>Showing 1-6 of 23</span>
                                            <select className='inpt' name="" id="">
                                                <option value="">1</option>
                                                <option value="">2</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Participants;