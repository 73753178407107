const initialState = {
    facebook: [],
    youtube: [],
    scriptreel: [],
    livereel: [],
    trimreel: [],
    syvid: [],
    sonority: [],
    videoreel: [],
    elevenlabs: [],
    autoresponder: [],
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    facebook: action.payload.data.facebook ? action.payload.data.facebook : [],
                    youtube: action.payload.data.youtube ? action.payload.data.youtube : [],
                    scriptreel: action.payload.data.scriptreel ? action.payload.data.scriptreel : [],
                    livereel: action.payload.data.livereel ? action.payload.data.livereel : [],
                    trimreel: action.payload.data.trimreel ? action.payload.data.trimreel : [],
                    syvid: action.payload.data.syvid ? action.payload.data.syvid : [],
                    sonority: action.payload.data.sonority ? action.payload.data.sonority : [],
                    videoreel: action.payload.data.videoreel ? action.payload.data.videoreel : [],
                    elevenlabs: action.payload.data.elevenlabs ? action.payload.data.elevenlabs : [],
                    autoresponder: action.payload.data.autoresponder ? action.payload.data.autoresponder : [],

                }
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}