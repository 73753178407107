import React, { useState } from 'react'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { Trash } from 'iconsax-react'
import { IoClose } from 'react-icons/io5'
import { onUpdateCourse } from '../../../Redux/Actions/CourseActions'
import { useNavigate } from 'react-router'


const TopicsRows = ({ curElem, aiSuggestion, setAiSuggestion, course }) => {
    let str = curElem.output_string.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loader, setLoader] = useState({
        update: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
        icon: <Trash />
    })

    const onDelete = (id, name) => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
            id: id,
            name: name
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
            name: ""
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "topic_user_search"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, aiSuggestion, setAiSuggestion, setSweet))
    }


    const handleUse = () => {
        let data = {
            courseId: course.id,
            projectName: course.projectName,
            topicName: str
        }
        setLoader({
            ...loader,
            update: true
        })
        dispatch(onUpdateCourse(data, navigate, loader, setLoader))
    }


    return (
        <li>
            <span className="close-recomnd" onClick={onDelete}><IoClose /></span>
            <div className="recomnd-single d-flex justify-content-between align-items-center">
                <p
                    style={{
                        textAlign: "left",
                        paddingRight: '23px',
                        color: '#fff',
                        margin: "auto 0"
                    }}
                >{str}</p>
                <button
                    type="button"
                    disabled={+curElem.isSelected}
                    style={+curElem.isSelected ? { background: "gray", cursor: "not-allowed" } : {}}
                    className="demoLink mt-0 ms-1"
                    onClick={handleUse}
                >
                    {loader.update ? <>Using <i className="fa fa-spinner fa-spin" /></> : "Use This"}
                </button>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure? You want to delete this topic?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </li >
    )
}

export default TopicsRows