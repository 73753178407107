import React from 'react'


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { FiBold, FiUnderline } from "react-icons/fi";
import { RiItalic, RiStrikethrough2 } from "react-icons/ri";
import { IoChevronDownOutline } from "react-icons/io5";

function TextAnime() {
  return (
    <>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className='text-center'>
            <Nav variant="v" className="tabStyleOne">
                <Nav.Item>
                    <Nav.Link eventKey="first">Text Overlays</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="second">Animations</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    
        <Tab.Content className='st-tabCont'>
        <Tab.Pane eventKey="first">
            <div className='adTxt'>
                <p>This is Serious Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                <div className='pt-3 text-end'>
                   <button className='demoLink small m-0'>Add Text Layer</button>
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Font</h6>
                <div>
                    <select style={{width: '14rem'}} className='inpt' name="" id="">
                        <option value="">Plus Jakarta Sans</option>
                    </select>
                </div>
                <div>
                    <select className='inpt' name="" id="">
                        <option value="">16</option>
                    </select>
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Pattern</h6>
                <div className='txtPattern'>
                    <div className='txtPattern-single active'><FiBold /></div>
                    <div className='txtPattern-single'><FiUnderline/></div>
                    <div className='txtPattern-single'><RiItalic /></div>
                    <div className='txtPattern-single'><RiStrikethrough2 /></div>
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Case</h6>
                <div className='txtPattern'>
                    <div className='txtPattern-single active'>TT</div>
                    <div className='txtPattern-single'>tt</div>
                    <div className='txtPattern-single'>Tt</div>
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Font Color</h6>
                <div className='fontColor'>
                    <div className='fontColor-in'></div>
                    <IoChevronDownOutline />
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Shadow</h6>
                <div className='fontColor'>
                    <div className='fontColor-in'></div>
                    <IoChevronDownOutline />
                </div>
                <div>
                    <input type="range" />
                </div>
                <input className='inpt' style={{width: 60, textAlign: 'center'}} placeholder='60%' type="text" />
            </div>

            <div className='textCard mt-4'>
                <h6>Alignment</h6>
                <div className='txtAlign'>
                    <span><i className='fa fa-align-left'></i></span>
                    <span><i className='fa fa-align-center'></i></span>
                    <span><i className='fa fa-align-right'></i></span>
                    <span><i className='fa fa-align-justify'></i></span>
                </div>
            </div>

            <div className='textCard mt-4'>
                <h6>Background</h6>
                <div className='fontColor'>
                    <div className='fontColor-in'></div>
                    <IoChevronDownOutline />
                </div>
                <div>
                    <input type="range" />
                </div>
                <input className='inpt' style={{width: 60, textAlign: 'center'}} placeholder='60%' type="text" />
            </div>

            <div className='textCard mt-4'>
                <h6>Opacity</h6>
                <div>
                    <input type="range" />
                </div>
                <input className='inpt' style={{width: 60, textAlign: 'center'}} placeholder='60%' type="text" />
            </div>
        </Tab.Pane>

        <Tab.Pane eventKey="second">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className='text-center'>
                    <Nav variant="v" className="tabStyleTwo">
                        <Nav.Item>
                           <Nav.Link eventKey="first">In</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                           <Nav.Link eventKey="second">Out</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                
                <Tab.Content className='pt-4'>
                    <Tab.Pane eventKey="first">
                        <div className='animeList'>
                            <ul>
                                <li>
                                    <div className='animeList-single selected'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/banned.svg').default} /></div>
                                        <p>Disable</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Typewriter</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-2.svg').default} /></div>
                                        <p>Pop-up</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Fade-In</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Bounce</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                       <div className='animeList'>
                            <ul>
                                <li>
                                    <div className='animeList-single selected'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/banned.svg').default} /></div>
                                        <p>Disable</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Typewriter</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-2.svg').default} /></div>
                                        <p>Pop-up</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Fade-In</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='animeList-single'>
                                        <div className='animeList-img'><img src={require('../../../images/editor/anime-1.svg').default} /></div>
                                        <p>Bounce</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Tab.Pane>

        </Tab.Content>

    </Tab.Container>
    </>
  )
}

export default TextAnime