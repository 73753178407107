import { produce } from "immer"
const initialState = {
    audio: {
        currentUrl: ""
    },
    layer: {
        canDelete: true
    },
    voiceOver: {
        isGenerating: false
    }
}
export const EditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_PLAY_MEDIA_EDITOR":
            return produce(state, (draft) => {
                draft.audio.currentUrl = action.payload
            })

        case "DISABLE_DELETE":
            return produce(state, (draft) => {
                draft.layer.canDelete = false
            })
        case "ENABLE_DELETE":
            return produce(state, (draft) => {
                draft.layer.canDelete = true
            })

        case "GENERATING_TTS":
            return produce(state, (draft) => {
                draft.voiceOver.isGenerating = true
            })
        case "GENERATION_DONE":
            return produce(state, (draft) => {
                draft.voiceOver.isGenerating = false
            })
        default:
            return state
    }
}