import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { onFetchPublicQuiz } from '../../Redux/Actions/PublicQuizActions'
import { useDispatch } from 'react-redux'
import StartQuizModal from './StartQuizModal'

const PublicQuiz = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: true
    })
    const [state, setState] = useState({
        name: "sadsdsadsad"
    })

    const fetchQuizData = () => {
        let data = { id }
        dispatch(onFetchPublicQuiz(data, setState, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchQuizData()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :

            <>
                <TitleBar title="Quiz" />
                <section className="siteWrap">
                    <div className="cont_wrap">
                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt'><span>{state.name}</span></h2>
                                <div>
                                    <h3>Instructions</h3>
                                    <ul className='mt-4'>
                                        <li>kdsakbdksabdksabdb</li>
                                        <li>tetteetetete</li>
                                    </ul>
                                    <button onClick={handleShow}>Take quiz</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <StartQuizModal
                    show={show}
                    handleClose={handleClose}
                    quizCustomization={state.quizCustomization}
                    userId={state.userId}
                    quizId={id}
                />
            </>
    )
}

export default PublicQuiz