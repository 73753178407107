import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import modalCloseIcon from '../../images/modal-close.png';
import { useNavigate } from 'react-router';
import { onCreateCourse } from '../../Redux/Actions/CourseActions';

const CreateCourseModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [courseName, setCourseName] = useState("")
    const [loader, setLoader] = useState({
        create: false
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            projectName: courseName
        }
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateCourse(data, navigate, handleClose, loader, setLoader))
    }

    // const handleFetch = (id) => {
    //     let data = { "courseId": id }
    //     commonAxios("course-fetch", data, dispatch, config)
    //         .then((res) => {
    //             if (res.status) {
    //                 setLoader(false)
    //                 dispatch(addCourse(res.data))
    //                 history.push("/create-topic")
    //             } else {
    //                 dispatch(setAlert(res.msg, "danger"))
    //             }
    //         }).catch(err => console.log(err))
    // }

    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec text-center p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Enter the Project Name</h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Project Name"
                            onChange={(e) => setCourseName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="inpBtn mt-3 create-modal-btn">Next {loader.create ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCourseModal