import React from "react";
import imac from "../../images/IMAC.png"
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import { useSelector } from "react-redux";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return(
        <>
            <section className="featureSec">
                    <div className="container">
                        <div className="featureSec-wrap">
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-12 col-md-6 order-1">
                                       <div className="featureSec-left">
                                           <h6>GET STARTED WITHOUT A PLAN</h6>
                                           <h2>All you need are your thoughts & voice to get started</h2>
                                           <p>Imagine going on a morning jog and thinking of a course to teach. For 5 days straight, record 5 minute audios. Upload on {rebrand? rebrand.name : "CourseReelX" } and you’re first video course is ready to be published on Udemy or Teachable.</p>
                                           <div className="textL">See how you can use {rebrand? rebrand.name : "CourseReelX" } to <span>create your first course</span></div>
                                       </div>
                                   </div>
                                   <div className="col-12 col-md-6 order-2">
                                       <div className="featureSec-right"><img src={mac} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-12 col-md-6">
                                       <div className="featureSec-left">
                                           <h6>Polish your videos effortlessly</h6>
                                           <h2>Pour creativity into your videos</h2>
                                           <p>Edit your videos with ease. Customize your slides the way you want without a rocket science degree. Just use our point-and-click video editing engine and make as robust as you’d like.</p>
                                           <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div>
                                       </div>
                                   </div>
                                   <div className="col-12 col-md-6">
                                       <div className="featureSec-right"><img src={mac2} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-12 col-md-6 order-1">
                                       <div className="featureSec-left">
                                           <h6>TAKE A BACKSEAT</h6>
                                           <h2>Let your team do it all for you</h2>
                                           <p>With {rebrand? rebrand.name : "CourseReelX" } Professional, share access with teams to create videos and courses for you. Provide reviews and comments to ensure everyone is on the same page.</p>
                                           <div className="textL">Find out how the <span>Team Members Access</span> feature works</div>
                                       </div>
                                   </div>
                                   <div className="col-12 col-md-6 order-2">
                                       <div className="featureSec-right"><img src={mac3} /></div>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default DashboardAbout;