import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import modalCloseIcon from '../../images/modal-close.png';
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';
import { useNavigate } from 'react-router';


const AiTemplates = () => {

    const navigate = useNavigate()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <TitleBar title="Ai Templates" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Back</button>
                                        <button className="demoLink" onClick={() => navigate('/promo-editor')}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt'><span>Select from AI-Suggested Templates</span></h2>
                                <p className='text-center pt-3'>Choose a Promo Video Theme from the following AI-Suggested Templates</p>

                                <div className='row pt-5'>
                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-3 col-md-6 col-sm-12'>
                                        <div className='videoProject-single'>
                                            <div className='videoProject-single-top'>
                                                <div className='video-card-wrapper-1 dashboard-card-bg'>
                                                    <div className='video-card-content'><img src="https://images.pexels.com/photos/29137971/pexels-photo-29137971/free-photo-of-scenic-autumn-pathway-lined-with-vibrant-leaves.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                                <div className='vidHover'>
                                                    <div className='vidiconAll'>
                                                        <div className='vidHover-single' onClick={handleShow}>
                                                            <span className='vidHover-icon'><i class="fa fa-eye"></i></span>
                                                            <span>Preview</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='videoProject-single-bottom pt-2'>
                                                <p style={{ color: '#BEC0C9' }}><strong>Engaging Teaser</strong> – Quick preview of the course’s highlights.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <Footer />


            <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>

                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className='p-3'>
                        <h4 className='gradientTxt mb-4'><span>Preview Template</span></h4>
                        <div className="modalVidWrap">
                            <div className="modalVid">
                                <iframe src="https://player.vimeo.com/video/746081609?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" allowfullscreen="" allow="autoplay,fullscreen,picture-in-picture" title="Player for CourseReel Intro" data-ready="true" tabindex="-1"></iframe>
                            </div>
                        </div>
                        <p style={{ color: '#BEC0C9' }} className='pt-4'>Engaging Teaser – Quick preview of the course’s highlights.</p>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default AiTemplates;