import React, { useState } from 'react'
import { onHandleChapterChange, onHandleCourseChange } from '../../../Redux/Actions/QuizActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const CoursesListRow = ({ curElem }) => {
    const dispatch = useDispatch()
    const quiz = useSelector(state => state.quiz.data)
    
    const handleCourseChange = (e) => {
        if (e.target.checked) {
            let chapIds = curElem.chapters.map((curElem) => {
                return {
                    id: curElem.id
                }
            })
            dispatch(onHandleCourseChange(e.target.checked, +e.target.value, chapIds))
        } else {
            dispatch(onHandleCourseChange(e.target.checked))
        }
    }

    const handleChapterChange = (e) => {
        if (e.target.checked) {
            dispatch(onHandleChapterChange(e.target.checked, +e.target.value))
        } else {
            dispatch(onHandleChapterChange(e.target.checked, +e.target.value))
        }
    }

    return (
        <div className='mb-4'>
            <div className='d-flex align-items-center justify-content-between'>
                <h5>{curElem.projectName}</h5>
                <div>
                    <label className='switch' htmlFor="">
                        <input
                            type="checkbox"
                            checked={+curElem.id === +quiz.courseContent.courseId}
                            value={curElem.id}
                            onChange={handleCourseChange}
                            style={{ zIndex: 2, width: "100%", height: "100%", position: "absolute", cursor: "pointer" }}
                        />
                        <span className="slider round" ></span>
                    </label>
                </div>
            </div>
            <hr className='mt-0' />
            {+curElem.id === +quiz.courseContent.courseId && curElem.chapters.length > 0 ?
                curElem.chapters.map((curChap, ind) => {
                    return (
                        <div className='d-flex align-items-center gap-2 mt-2 chap-name' key={ind}>
                            <div className='style-checkbox'>
                                <input
                                    type="checkbox"
                                    checked={quiz.courseContent.chapters.find(({ id }) => +id === +curChap.id)}
                                    value={curChap.id}
                                    onChange={handleChapterChange}
                                />
                                <span className='checkbox-select'></span>
                            </div>
                            <h6>{curChap.name}</h6>
                        </div>
                    )
                })
                : ""}
        </div>
    )
}

export default CoursesListRow