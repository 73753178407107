import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { onChangeQuizSource } from '../../../Redux/Actions/QuizActions'

const QuizSource = () => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)

  const handleChange = (value) => {
    dispatch(onChangeQuizSource(value))
  }

  return (
    <>
      <div className='quiz-card'>
        <div className='quiz-source py-4'>
          <h5 className='text-center gradientTxt pb-4'><span>Select Quiz Source</span></h5>
          <div className='quiz-source-single mt-2'>
            <div className='row align-items-center justify-content-between'>
              <div className='col'>
                <p>From Course Content</p>
              </div>
              <div className='col-auto'>
                <button className='btnBlue' onClick={() => handleChange("course")} style={{ background: quiz.quizSource === "course" ? "green" : "" }}>
                  {quiz.quizSource === "course" ? "Using" : "Use This"}
                </button>
              </div>
            </div>
          </div>
          <div className='quiz-source-single mt-2'>
            <div className='row align-items-center justify-content-between'>
              <div className='col'>
                <p>Manual Prompt</p>
              </div>
              <div className='col-auto'>
                <button className='btnBlue' onClick={() => handleChange("manual")} style={{ background: quiz.quizSource === "manual" ? "green" : "" }}>
                  {quiz.quizSource === "manual" ? "Using" : "Use This"}
                </button>
              </div>
            </div>
          </div>
          {/* <div className='quiz-source-single mt-2'>
            <div className='row align-items-center justify-content-between'>
              <div className='col'>
                <p>Predefined Templates</p>
              </div>
              <div className='col-auto'>
                <button className='btnBlue'>Use This</button>
              </div>
            </div>
          </div> */}
        </div>
      </div >
    </>
  )
}

export default QuizSource