import React, { useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';


const PreFilledLandingPage = () => {


    return (
        <>
            <TitleBar title="Pre-filled Landing Page" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Save as Draft</button>
                                        <button className="demoLink">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt'><span>Pre-filled Sections</span></h2>
                                <h6 className='text-center pt-2'>The Landing Page Builder automatically fills in relevant sections</h6>

                                <div className='quiz-card mt-5'>
                                    <div className='p-2'>
                                        <h5 className='gradientTxt'><span>Hero Section</span></h5>
                                        <div className='hero-sec'>
                                            <div className='hero-sec-left'>
                                                <div className='mt-4'>
                                                    <label className='label' htmlFor="">Title</label>
                                                    <input className='simple-input' type="text" value='10 Delicious and Easy One-Pot Meals for   Buysy Week Nights' />
                                                </div>
                                                <div className='mt-4'>
                                                    <label className='label' htmlFor="">Tagline</label>
                                                    <input className='simple-input' type="text" value='Learn How To Cook Like A Pro' />
                                                </div>
                                            </div>
                                            <div className='hero-sec-right'>
                                                <div className='mt-4'>
                                                   <label className='label' htmlFor="">Banner Image</label>
                                                   <div className='banner-image'><img src="https://images.pexels.com/photos/1571938/pexels-photo-1571938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-5'>
                                           <h5 className='gradientTxt'><span>About the Course Section</span></h5>
                                           <div className='mt-4'>
                                                <label className='label' htmlFor="">Course Objectives</label>
                                                <textarea className='simple-input' name="" id="" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"></textarea>
                                            </div>
                                            <div className='mt-4'>
                                                <label className='label' htmlFor="">Overview</label>
                                                <textarea className='simple-input' name="" id="" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"></textarea>
                                            </div>
                                        </div>

                                        <div className='mt-5'>
                                           <h5 className='gradientTxt'><span>Bio Section</span></h5>
                                           <div className='mt-4'>
                                                <label className='label' htmlFor="">Instructor Bio</label>
                                                <textarea className='simple-input' name="" id="" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"></textarea>
                                            </div>
                                        </div>

                                        <div className='mt-5'>
                                           <h5 className='gradientTxt'><span>Benefits Section</span></h5>
                                           <div className='mt-4'>
                                                <label className='label' htmlFor="">Benefits</label>
                                                <textarea className='simple-input' name="" id="" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"></textarea>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <Footer />

        </>
    )
}

export default PreFilledLandingPage;