import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { removeLogoWatermark, updateLogoWatermark, updateStatus } from '../../../Redux/Actions/VideoActions';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../Global/Global';
import sample from "../../../images/sample.png";

const LogoWatermark = (props) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false
    })

    const selectImage = (url, lwType) => {
        let uploadType = 'ADD_LOGO';
        if (lwType === "watermark") {
            uploadType = 'ADD_WATERMARK';
        }
        dispatch(updateLogoWatermark(uploadType, url));
    }

    const handleLogoWaterMark = (e) => {
        dispatch(updateStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            if (props.type === "logo") {
                dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
            } else {
                dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
            }
        }
    }
    const logoWaterPos = (position) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if (props.type === 'watermark') {
            fileType = 'UPDATE_WATERMARK_POSITION';
        }
        dispatch(updateLogoWatermark(fileType, position));
    }

    const onLoadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
                if (file.size < 5000000) {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_type', props.type);
                    setLoader(true)
                    dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, props.type))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title">
                    <h2 className='text-capitalize'>Add {props.type}</h2>
                    <div className="switch-single">
                        <label className="switch" >
                            <input
                                type="checkbox"
                                name={props.type}
                                id={props.type}
                                checked={props.data.enable}
                                onChange={(e) => handleLogoWaterMark(e)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {props.data.enable ?
                    <>
                        <div className="fileUpload mt-4" >
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i> {props.type}
                            <input
                                type="file"
                                className='cursor-pointer'
                                onChange={(e) => onLoadFile(e)}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loader.upload ? <i className="fa fa-spinner fa-spin loader-center" />
                                                :
                                                <img className="uploadImg" src={props.data.src ? props.data.src : sample} alt="" />
                                            // <img
                                            //     src={logo.src ?
                                            //         logo.src : sample}
                                            //     alt=""
                                            //     className="uploadImg"
                                            // />
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="itemDisp-right">
                                <select onChange={(e) => logoWaterPos(e.target.value)} className='cursor-pointer'>
                                    <option value={'top-left'}>Top Left</option>
                                    <option value={'top-center'}>Top Center</option>
                                    <option value={'top-right'}>Top Right</option>
                                    <option value={'bottom-left'}>Bottom Left</option>
                                    <option value={'bottom-center'}>Bottom Center</option>
                                    <option value={'bottom-right'}>Bottom Right</option>
                                </select>
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </div>

        // <>
        //     <div className="opt-select mb-3">
        //         <span className='text-capitalize'>{props.type}</span>
        //         <label className="switch">
        //             <input
        //                 type="checkbox"
        //                 name={props.type}
        //                 id={props.type}
        //                 checked={props.data.enable}
        //                 onChange={(e) => handleLogoWaterMark(e)}
        //             />
        //             <span className="slider round"></span>
        //         </label>
        //     </div>
        //     {props.data.enable ?
        //         <>
        //             <div className="upload mt-3">
        //                 {loader.upload ?
        //                     <div className='percent-loader-box'>
        //                         <span className='loader-percent'>{percent}%</span>
        //                     </div> :
        //                     <div className="d-flex justify-content-evenly align-items-center" style={{ height: "100%", width: "100%" }}>
        //                         <div>
        //                             <FiUpload />
        //                             <p className='text-capitalize'>Upload {props.type}</p>
        //                             <input
        //                                 type="file"
        //                                 onChange={(e) => onLoadFile(e)}
        //                             />
        //                         </div>
        //                         <div className="up_img">
        //                             <img style={{ objectFit: 'contain', height: 82 }} src={props.data.src ? props.data.src : dummyImage} alt="" />
        //                         </div>
        //                     </div>


        //                 }
        //             </div >

        //             <div className="opt-select pt-3">
        //                 <span className='text-capitalize'>{props.type} Position</span>
        //             </div>
        //             <div className="position mb-3">
        //                 <ul>
        //                     {position.map((curElem, index) => {
        //                         return (
        //                             <li key={index}>
        //                                 <div
        //                                     className={`position-single ${props.data.pos === curElem ? "active" : ""}`}
        //                                     onClick={() => logoWaterPos(curElem, 'logo')}
        //                                     style={{ cursor: "pointer" }}
        //                                 >
        //                                 </div>
        //                             </li>
        //                         )
        //                     })}
        //                 </ul>
        //             </div>
        //         </>
        //         : ""}
        // </>
    );
}

export default LogoWatermark;
