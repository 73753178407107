import React, { useEffect, useState } from 'react'
import Footer from '../../../CommonComponents/Footer/Footer'
import TitleBar from '../../../CommonComponents/TitleBar'
import Navbar from '../../../CommonComponents/Navs/Navbar'
import SideNav from '../../../CommonComponents/Navs/SideNav'
import { IoArrowBackSharp, IoClose, IoReloadOutline } from 'react-icons/io5'
import { MdDragIndicator } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import CourseHeader from '../CourseHeader'
import { onCreateChapters, onFetchAiData, onFetchCourse, onGetAiData } from '../../../../Redux/Actions/CourseActions'
import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import YourChapterRows from './YourChapterRows'
import { useImmer } from 'use-immer'
import SuggestedChapterRows from './SuggestedChapterRows'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const CreateChapter = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()



    const { id } = queryString.parse(location.search)
    const course = useSelector(state => state.course.data)

    const [suggestion, setSuggestion] = useState([])
    const [fieldArr, setFieldArr] = useImmer([])

    const [editChapter, setEditChapter] = useImmer({
        name: "",
        rowId: 0,
        type: "custom"
    })
    const [fetchLoader, setFetchLoader] = useState({
        fetch: false
    })

    const [loader, setLoader] = useState({
        create: false,
        aiLoader: false,
    })

    const handleChange = (e) => {
        setEditChapter({
            ...editChapter,
            name: e.target.value
        })
    }
    const handleAdd = () => {
        if (editChapter.name) {
            setFieldArr((draft) => {
                draft.push(editChapter)
            })
            setEditChapter((draft) => {
                draft.name = ""
                draft.rowId = 0
                draft.type = "custom"
            })
        }
    }

    const getAiData = () => {
        let data = {
            text: course.topicName,
            type: "chapter",
            limit: 0,
            courseId: id
        }
        setLoader({
            ...loader,
            aiLoader: true,
            fetch: false
        })
        dispatch(onGetAiData(data, setSuggestion, loader, setLoader))
    }

    const handleCopy = (text, rowId) => {
        setFieldArr((draft) => {
            draft.push({ name: text, rowId, type: "AI" })
        })
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(fieldArr, result.source.index, result.destination.index);
        setFieldArr(items)
    }


    const handleSubmit = () => {
        let data = {
            courseId: id,
            chapters: fieldArr
        }
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateChapters(data, navigate, loader, setLoader))
    }


    const fetchCourseAiData = () => {
        let data = {
            courseId: id,
            type: "chapter",
        }
        dispatch(onFetchAiData(data, setSuggestion, getAiData))
    }


    const fetchCourse = () => {
        let data = {
            courseId: id
        }
        dispatch(onFetchCourse(data, fetchLoader, setFetchLoader))
    }

    useEffect(() => {
        if (course.topicName) {
            fetchCourseAiData()
        }
    }, [course.topicName])

    useEffect(() => {
        if (id) {
            fetchCourse()
        }
    }, [id])

    return (
        fetchLoader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Create Chapter" />
                <Navbar />
                <section className="siteWrap">


                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>

                        <div className="cont_area">

                            <div className="create-course-top flex">

                                <div className="course-top-left">
                                    <h2>
                                        <CourseHeader
                                            courseName={course.projectName}
                                            courseId={course.id}
                                            topicName={course.topicName}
                                        />
                                        <span style={{ display: 'inline-block', paddingLeft: 5 }}>| {course.topicName}</span>

                                    </h2>
                                </div>

                                <div className="projectTitle-right">
                                    <button className="demoLink grey m-0" onClick={() => navigate(-1)}><IoArrowBackSharp /> Back</button>
                                    <button
                                        type="submit"
                                        className="demoLink m-0"
                                        disabled={fieldArr.length === 0}
                                        style={fieldArr.length > 0 ? {} : { background: "gray", cursor: "not-allowed" }}
                                        onClick={handleSubmit}

                                    >{loader.create ? <> <span style={{ fontWeight: 'bold' }}>Saving</span> <i className="fa fa-spinner fa-spin mr-2" /> </> : "Continue"}</button>
                                </div>

                            </div>

                            <div className="create-course-mid alt">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="gradientTxt pb-1"><span>Chapters</span></h2>
                                            <p>Organize your Chapters</p>

                                            <div className="">
                                                <div className="topic-key" >
                                                    <input
                                                        className="courseInp"
                                                        type="text"
                                                        required
                                                        name="name"
                                                        value={editChapter.name}
                                                        onChange={handleChange}
                                                        style={{ maxWidth: "900px" }}
                                                    />
                                                    <button className="demoLink" onClick={handleAdd}>Add</button>
                                                </div>
                                            </div>

                                            <div className="recomnd mt-4">
                                                <h5 className="gradientTxt"><span>All Your Chapters</span></h5>
                                                <p className="pt-2" style={{ fontSize: '0.8rem' }}>Drag to Arrange your courses in the Order of which you would like them</p>
                                                <p className="pt-2" style={{ fontSize: '0.8rem', color: '#FFFFFF' }}>Double Click to Edit Title</p>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="droppable" direction="vertical">
                                                        {(provided) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                className="chapterList mt-4"
                                                            >
                                                                {fieldArr.length > 0 ?
                                                                    fieldArr.map((curElem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                                                    {(provided) => (
                                                                                        <YourChapterRows
                                                                                            curElem={curElem}
                                                                                            setFieldArr={setFieldArr}
                                                                                            index={index}
                                                                                            setEditChapter={setEditChapter}
                                                                                            provided={provided}
                                                                                        />
                                                                                    )}
                                                                                </Draggable>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    :
                                                                    <div className='text-center'>
                                                                        You do not have chapters!
                                                                    </div>
                                                                }
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>


                                        <div className="col-lg-6">
                                            <div>
                                                <div className="recomnd">
                                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                                        <h5 className="gradientTxt"><span>Recommend Chapters</span></h5>

                                                        {suggestion.length === 0 ? "" :
                                                            <button
                                                                type="button"
                                                                className="simple-btn"
                                                                onClick={() => getAiData(true)}
                                                            >
                                                                <span>{loader.aiLoader ? <>Loading More Recommendations <i className="fa fa-spinner fa-spin mx-2" /></> : "Load More Recommendations"} </span>

                                                            </button>
                                                        }
                                                    </div>

                                                    <div className="recomnd-scroll">
                                                        <ul>
                                                            {suggestion.length > 0 ?
                                                                suggestion.map((curElem, index) => {
                                                                    let str = curElem.output_string.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')
                                                                    let status = fieldArr.findIndex(({ rowId }) => +rowId === +curElem.id)
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <SuggestedChapterRows
                                                                                curElem={curElem}
                                                                                handleCopy={handleCopy}
                                                                                str={str}
                                                                                status={status}
                                                                                suggestion={suggestion}
                                                                                setSuggestion={setSuggestion}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                :
                                                                ''}
                                                            {loader.aiLoader ?
                                                                <div className="text-center">
                                                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: '#ce88ff', fontSize: '25px' }} />
                                                                </div>
                                                                : ""
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="row justify-content-center">
                                        <div className="col-xl-12">
                                        </div >
                                    </div > */}
                                </div >
                            </div >

                        </div>
                    </div>




                </section >

                <Footer />
            </>
    )
}

export default CreateChapter