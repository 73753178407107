import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onUpdateCourseName } from '../../../Redux/Actions/CourseActions'

const CourseHeader = (props) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")

    const handeEditCour = (e) => {
        setText(e.target.value)
    }
    const handleSubmit = () => {
        if (text != "") {
            let data = {
                courseId: props.courseId,
                projectName: text,
                topicName: props.topicName
            }
            dispatch(onUpdateCourseName(data))
        }
    }
    useEffect(() => {
        setText(props.courseName)
    }, [props.courseName])

    return (
        <>
            {
                <input
                    style={{ width: text.length + "ch", paddingLeft: "5px" }}
                    value={text}
                    onChange={handeEditCour}
                    className="cour-edit"
                    onBlur={handleSubmit}
                />
            }
        </>
    )
}

export default CourseHeader