import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../../images/modal-close.png';
import { IoMdMic, IoMdPause, IoMdPlay, IoMdSquare } from "react-icons/io"
// import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import useRecorder from '../../CommonComponents/MyRecording';

let newIntervalId, music = false
const RecordAudioModal = ({ show3, handleClose3, onInputFile}) => {

    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording] = useRecorder();

    const [play, setPlay] = useState(false)
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        else if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        else if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        else if (type === "stop") {
            if (status.start || status.pause || status.resume) {
                stopRecording()
                setT(false)
                clearInterval(newIntervalId);
                let totalTime = timer.h * 3600 + timer.m * 60 + second
                setAudioDuration(totalTime)
                setSecond(0)
                setTimer({
                    ...timer,
                    h: 0,
                    m: 0
                })
                return;
            }
        }

    };

    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    const handlePlay = () => {
        if (audioURL) {
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
            setPlay(true)
        }
    }

    // const handlePause = () => {
    //     music.pause()
    //     setPlay(false)

    // }


    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handleSelectRecording = () => {
        const file = new File([audioURL], "audio.mp3", { type: "audio/mpeg" });
        if (audioURL !== "") {
            let data = {
                target: {
                    files: [file]
                }
            }
            handleClose3()
            onInputFile(data)
        } 
    }

    return (
        <Modal className="VideoModal small white" show={show3} onHide={handleClose3} centered>
            <Modal.Body className="audioModal text-center">
                <div onClick={handleClose3} className="vidClose"><img src={modalCloseIcon} /></div>
                <h4 id="rec-title" class="modal-title text-white mb-2">Record Audio</h4>

                <span id="time-display" class="mb-2 text-white">{timer.h < 10 ? "0" : ""}{timer.h}:{timer.m < 10 ? "0" : ""}{timer.m}:{second < 10 ? "0" : ""}{second}</span>
                <div className='d-flex'>
                    {status.start ?
                        !status.pause ?
                            <button
                                id="record"
                                className="bg-white d-flex w-100 mx-auto rounded-circle"
                                onClick={() => handleClick("pause")}
                                title="Pause"
                            >
                                <IoMdPause
                                    size={28}
                                />
                            </button>
                            :
                            <button
                                id="record"
                                className="bg-white d-flex w-100 mx-auto rounded-circle"
                                onClick={() => handleClick("resume")}
                                title="Resume"
                            >
                                <IoMdPlay
                                    size={28}
                                />
                            </button>
                        :
                        <button
                            id="record"
                            className="bg-white d-flex w-100 mx-auto rounded-circle"
                            onClick={() => handleClick("start")}
                            title="Start"
                        >
                            <IoMdMic
                                size={28}
                            />
                        </button>
                    }
                    <button
                        onClick={() => handleClick("stop")}
                        id="record"
                        className="bg-white d-flex w-100 mx-auto rounded-circle my-1"
                        title='Stop'
                    >
                        <IoMdSquare
                            size={25}
                            color={status.start ? "red" : ""}
                        />
                    </button>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer border-top-0 justify-content-center bg-white pt-4 pb-4">
                <button
                    type="submit"
                    className="inpBtn auto"
                    onClick={handlePlay}
                >
                    {play ? "Playing" : "Play"}
                </button>
                <button
                    type="submit"
                    className="inpBtn auto"
                    onClick={handleSelectRecording}
                    title="upload to transcribe"
                >
                    Done
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default RecordAudioModal;