import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeEmail } from "../../Redux/Actions/AuthActions";

const PrivacyEmail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }


    return (
        <div className="profileBlock-wrap">
            <h2 className="infoTitle">Change Email</h2>
            <div className="profileBlock mt-0">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label>Current Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Current Email Address"
                                    value={auth.user?.email}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-alt">
                                <label>New Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>{loader ? <>Updating <i className="fa fa-spinner fa-spin mr-2" /></> : 'Update'}</button>

                </form>
            </div>
        </div>
    )
}

export default PrivacyEmail;