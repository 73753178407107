import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'


const SuggestedChapterRows = ({ curElem, handleCopy, status, str, suggestion, setSuggestion }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
            id: curElem.id,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "chapter_user_search"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, suggestion, setSuggestion, setSweet))
    }

    return (
        <li>
            <span className="close-recomnd" onClick={onDelete}><IoClose /></span>
            <div className="recomnd-single d-flex justify-content-between align-items-center">
                <p
                    style={{
                        textAlign: "left",
                        paddingRight: '23px',
                        color: '#fff',
                        margin: "auto 0"
                    }}
                >{str}</p>
                {status !== -1 ?
                    <a className="demoLink mt-0 " style={{ color: '#fff', background: "gray", cursor: "not-allowed" }}>Added</a>
                    :
                    <a className="demoLink mt-0 " onClick={() => handleCopy(str, curElem.id)} style={{ color: '#fff' }}>Add This</a>}
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure? You want to delete this chapter?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}

            />
        </li>
    )
}

export default SuggestedChapterRows