import React, { useEffect, useState } from 'react';
// import BgImageMedia from './BgImageMedia';
import BgVideoMedia from './BgVideoMedia';
import BgColor from './BgColor';
import { Tab, Nav } from "react-bootstrap";
import UploadComponent from '../uploadFiles/UploadComponent';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateBackground } from '../../../Redux/Actions/VideoActions';
import Library from '../../CommonComponents/Library';
import Upload from '../../CommonComponents/Upload';
// import UploadComponent from '../../uploadFiles/UploadComponent';
import rgbHex from 'rgb-hex';
import { SketchPicker } from 'react-color';

const BackgroundMedia = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [bgColor, setBgColor] = useState("")
    const [checkMedia, setCheckMedia] = useState(false)


    const handleBackground = (val, type) => {
        if (type === "images") {
            let data = { ...val }
            dispatch(updateBackground(data, selectedSlideIndex, "image"))
        } else if (type === "video") {
            let data = { ...val }
            dispatch(updateBackground(data, selectedSlideIndex, "video"))
        } else if (type === "color") {
            let rgba = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a.toFixed(2)})`
            let hex = `#${rgbHex(rgba)}`
            setCheckMedia(false)
            dispatch(updateBackground(hex, selectedSlideIndex, "color"))
        }
        else if (type === "ui") {
            let data = {
                url: val
            }
            dispatch(updateBackground(data, selectedSlideIndex, "image"))
        }
    }

    useEffect(() => {
        if (selectedSlide) {
            let selectedIndex = selectedSlide.background.findIndex(({ isSelected }) => +isSelected === 1)
            if (selectedSlide.background[selectedIndex].type === "color") {
                setBgColor(selectedSlide.background[selectedIndex].src)
            }
        }
    }, [selectedSlide])
    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="video-tab">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="color-tab">Color</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <Library
                                handleBackground={handleBackground}
                                sType={"images"}
                                type="layer"
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                            {/* <BgImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                            /> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="video-tab">
                            <Library
                                handleBackground={handleBackground}
                                sType={"video"}
                                type="layer"
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                            {/* <BgVideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                            /> */}

                        </Tab.Pane>
                        <Tab.Pane eventKey="color-tab">
                            <div className="innertab">
                                <div className="innertab-scroll">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="sketch-picker-wrapper">
                                                <div className="sketch-picker-head">
                                                    <h4>Choose a Background Color</h4>
                                                </div>
                                                <SketchPicker
                                                    color={bgColor}
                                                    onChange={(e) => handleBackground(e, "color")}
                                                    className="color-picker-style"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">
                            <Upload
                                from="videoEditor"
                                handleBackground={handleBackground}
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                            {/* <UploadComponent
                                type="media"
                                tabType="backgroundMedia"
                                selectedSlideIndex={selectedSlideIndex}
                            /> */}

                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};


export default BackgroundMedia;
