import { produce } from "immer"

const initialState = {
    data: {
        courseId: "",
        created: "",
        modified: "",
        projectName: "",
        status: "",
        thumbnail: "",
        topicName: null,
        chapters: [],
        coursesAll: false
    }
}

export const CourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_COURSE_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "HANDLE_COURSE_CHANGE":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "SET_COURSE_CHAPTER":
            return produce(state, (draft) => {
                let chapIndex = draft.data.chapters.findIndex(({ id }) => +id === +action.payload.chapterId)
                if (chapIndex !== -1) {
                    let val = { ...action.payload.obj }
                    val.createdFrom.type = action.payload.type === "music" ? "audio" : action.payload.type
                    draft.data.chapters[chapIndex] = val

                }
            })

        case "ON_SAVE_CHAPTER_SCRIPT":
            return produce(state, (draft) => {
                let chapIndex = draft.data.chapters.findIndex(({ id }) => +id === +action.payload.chapterId)
                if (chapIndex !== -1) {
                    draft.data.chapters[chapIndex].createdFrom.text = action.payload.text
                }
            })
        default:
            return state
    }
}