import React from 'react'

import { FiTrash2, FiUpload } from "react-icons/fi";
import { BsStars } from "react-icons/bs";
import { MdOutlineRecordVoiceOver } from "react-icons/md";

function PromoVoice() {
  return (
    <>
    <div className="promo-editor-card scroll-1">
        <h6>Voiceovers</h6>
        <p>Import your voice. You can record it or upload a file</p>
        <div className='gradient-box mt-3'>
            <div className='upload-field'>
            <FiUpload />
            <span>Upload pre-recorded voiceover</span>
            <input type="file" />
            </div>
        </div>

        <h6 className="pt-4">AI-Generated Voiceovers</h6>
        <p>Generate a Voiceover by converting text-to-speech.</p>

        <div className='gradient-box mt-3'>
            <select className='input' name="" id="">
                <option value="">Choose language</option>
            </select>
        </div>
        <div className='gradient-box mt-3'>
            <select className='input' name="" id="">
                <option value="">Select Tone</option>
            </select>
        </div>

        <div className='gradient-box mt-3'>
            <div className='upload-field'>
            <p style={{fontSize: '0.8rem', lineHeight: '22px'}}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
            </div>
        </div>
        <div className='d-flex align-items-center justify-content-between mt-3'>
            <button className='demoLink small m-0'><BsStars /> Generate Voice</button>
            <button className='demoLink small m-0'><MdOutlineRecordVoiceOver /> Use Voice</button>
        </div>
    </div>
    </>
  )
}

export default PromoVoice