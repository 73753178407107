import React, { useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';


const ContentExtraction = () => {


    return (
        <>
            <TitleBar title="Content Extraction" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-auto">
                                    <h2 className='pageTitle-2'>Title of Project Here</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button className="demoLink grey">Save as Draft</button>
                                        <button className="demoLink">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className='quiz-wrap'>
                                <h2 className='text-center gradientTxt'><span>Content Extraction</span></h2>
                                <h6 className='text-center pt-2'>The AI analyzes course data to extract key elements for the landing page</h6>

                                <div className='quiz-card mt-5'>
                                    <div className='p-3'>
                                        <h5 className='gradientTxt'><span>Select Key Elements You Want AI to Analyze  for Your Landing Page</span></h5>
                                        <div className='question-type auto-width'>
                                            <ul>
                                                <li>
                                                <div className='question-type-single d-flex align-items-center'>
                                                    <div className='d-flex'>
                                                    <div className='col-auto'>
                                                        <div className='radio-style'></div>
                                                    </div>
                                                    <div className='col'>
                                                        <h6>Course Title</h6>
                                                    </div>
                                                    </div>
                                                    <input type="radio" name='group-3' />
                                                </div>
                                                </li>
                                                <li>
                                                <div className='question-type-single d-flex align-items-center'>
                                                    <div className='d-flex'>
                                                    <div className='col-auto'>
                                                        <div className='radio-style'></div>
                                                    </div>
                                                    <div className='col'>
                                                        <h6>Description and Objectives</h6>
                                                    </div>
                                                    </div>
                                                    <input type="radio" name='group-3' />
                                                </div>
                                                </li>
                                                <li>
                                                <div className='question-type-single d-flex align-items-center'>
                                                    <div className='d-flex'>
                                                    <div className='col-auto'>
                                                        <div className='radio-style'></div>
                                                    </div>
                                                    <div className='col'>
                                                        <h6>Key Features</h6>
                                                        <p>(video hours, downloadable resources, quizzes)</p>
                                                    </div>
                                                    </div>
                                                    <input type="radio" name='group-3' />
                                                </div>
                                                </li>
                                                <li>
                                                <div className='question-type-single d-flex align-items-center'>
                                                    <div className='d-flex'>
                                                    <div className='col-auto'>
                                                        <div className='radio-style'></div>
                                                    </div>
                                                    <div className='col'>
                                                        <h6>Target Audience</h6>
                                                    </div>
                                                    </div>
                                                    <input type="radio" name='group-3' />
                                                </div>
                                                </li>
                                                <li>
                                                <div className='question-type-single d-flex align-items-center'>
                                                    <div className='d-flex'>
                                                    <div className='col-auto'>
                                                        <div className='radio-style'></div>
                                                    </div>
                                                    <div className='col'>
                                                        <h6>Testimonials (if available)</h6>
                                                    </div>
                                                    </div>
                                                    <input type="radio" name='group-3' />
                                                </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <h5 className='gradientTxt mt-4 mb-4'><span>Choose Courses to extract content from</span></h5>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                            <div className='gradient-box'>
                                        <div className='gradient-box-in all-border'>
                                            <div className='slide-selection'>
                                            <ul>
                                                <li>
                                                    <div className='slide-selection-single alt'>
                                                        <div className='row align-items-center'>
                                                           <div className='col-auto'>
                                                            <div className='thumbImg'><img src={require('../../images/quiz/vid-thumb.svg').default} alt='' /></div>
                                                           </div>
                                                           <div className='col'>
                                                            10 Delicious and Easy One-Pot Meals for Busy Weeknights
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='style-checkbox'>
                                                                    <input type="checkbox" />
                                                                    <span className='checkbox-select'></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='slide-selection-single alt'>
                                                        <div className='row align-items-center'>
                                                           <div className='col-auto'>
                                                            <div className='thumbImg'><img src={require('../../images/quiz/vid-thumb.svg').default} alt='' /></div>
                                                           </div>
                                                           <div className='col'>
                                                            10 Delicious and Easy One-Pot Meals for Busy Weeknights
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='style-checkbox'>
                                                                    <input type="checkbox" />
                                                                    <span className='checkbox-select'></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <Footer />

        </>
    )
}

export default ContentExtraction;