import React, { useEffect, useState } from "react";
import HelpSubContent from "./HelpSubContent";
import { useSelector } from "react-redux";

const HelpContent = (props) => {

    const supportData = useSelector(state => state.help);
    const [articlesData, setArticlesData] = useState(supportData.article);
    const [q, setQ] = useState('');

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }
    useEffect(() => {
        setArticlesData(supportData.article)
    }, [supportData.article])

    return (
        <>

            <section className="siteWrap">
                <section className="help-banner">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-8">
                                <div className="help-banner-in">
                                    <h2>What do you want to <span>know?</span></h2>
                                    <div className="helpSearch">
                                        <div className="helpSearch-left">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                            <input type="text"
                                                placeholder="Search"
                                                onChange={(e) => onSearch(e)}
                                            />
                                        </div>
                                        <div className="helpSearch-right">
                                            <button className="demoLink">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="impTopic">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="impTopic-main">
                                    <h2>Important Topics</h2>
                                    {search(articlesData).length > 0 ?
                                        <div className="row">

                                            {
                                                articlesData.length > 0 ?
                                                    search(articlesData).map((article, index) => {
                                                        return (
                                                            <HelpSubContent
                                                                name={article.name}
                                                                image={article.logo}
                                                                url={`/training?id=${article.id}`}
                                                                key={index}
                                                            />
                                                        )
                                                    })
                                                    : ''
                                            }
                                            {


                                                props.loadArticles ?
                                                    <div className="col-12">
                                                        <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                                    </div>
                                                    : ''
                                            }
                                        </div> : <div style={{ fontSize: "30px", color: "white", display: "flex", justifyContent: "center" }}>No Data Available</div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>

        </>
    )
}

export default HelpContent;