import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeModalx from "../../images/modal-close.png"
import { deleteSocialAccounts, onConnectInte } from "../../Redux/Actions/SocialActions";
import { MdDeleteForever } from "react-icons/md";
import CommonAlert from "../CommonComponents/CommonAlert";
import ConnectModal from "./ConnectModal";


const ConnectReelapps = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        submit: false,
        fetch: false
    })
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [appDetails, setAppDetails] = useState({
        user_id: auth.user.id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false,
    })

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteSocialAccounts("delete-integration", data, setSweet))
    }

    const onInputChange = (e) => {
        setAppDetails({ ...appDetails, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        let data = { ...appDetails }
        if (props.type === "autoresponder") {
            // delete data.apiKey
            // let element = document.getElementById("auto-form")
            // let form = element.getElementsByTagName("form")
            // let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            // formData.postUrl = form[0].action
            let { name, code } = appDetails;
            data = { name, code }
        }
        if (props.type === "twilio") {
            data.app_name = "twilio"
        }
        setLoader({
            ...loader,
            submit: true
        })
        dispatch(onConnectInte(props.url, data, handleClose, loader, setLoader))
    }
    console.log(props.url)
    const handleConnect = () => {
        if (props.type === "elevenlabs" && props.data.length === 1) { }
        else {
            handleShow()
        }
    }

    return (
        <>
            <div className="connection-box mt-3" id={props.type}>
                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length ? '' : 'disable-img'}`}>
                        <img src={props.icon} width="56px" alt={props.name} />
                    </div>
                    <div className="connection-txt">
                        <div className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                            <div className="facebook-content">
                                <h6>{props.name}</h6>
                                {props.data.length === 0 ? <p className="m-0">Not Connected</p> : ""}
                            </div>
                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                <button onClick={handleConnect} className="btn-change7">Connect</button>
                            </div>
                        </div>
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="facebook-delete d-flex justify-content-between  align-items-center mt-2">
                                                <div className="facebook-content">
                                                    <h6>{item.name} {item?.email}</h6>
                                                    <p className="m-0">Added on {item.created}</p>
                                                </div>
                                                <button onClick={(e) => onDelete(item.id)} type="button" className="btn-change7">Disconnect</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : ""
                            // <div className="noConnect">
                            //     <div className="youbtube-connection text-white">
                            //         <div className="youtube-content">
                            //             <h6>{props.name}</h6>
                            //             <p className="m-0">Not Connected</p>
                            //         </div>
                            //     </div>
                            //     <div className="facebook-connect-account">
                            //         <button onClick={handleConnect} className="btn-change7">Connect</button>
                            //     </div>
                            // </div>
                        }

                    </div>
                </div>


            </div>

            <div className={`modal ${show ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ margin: "auto" }}>
                                <img src={props.icon} width="56px" alt={props.name} className="mr-2" /> {`Connect ${props.name}`}
                            </h5>
                            <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true"> */}
                                {/* <i className="fas fa-times-circle" style={{color: "#ffffff"}} ></i></span> */}
                                <img src={closeModalx} alt="" />
                            </button>
                        </div>
                        <ConnectModal
                            type={props.type}
                            onFormSubmit={onFormSubmit}
                            onInputChange={onInputChange}
                            loader={loader}
                        />
                    </div>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to disconnect this ${props.name} account?`}
                confirmButtonColor={""}
                icon={<MdDeleteForever size={18} />}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default ConnectReelapps;