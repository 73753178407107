import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addTransition, removeTransition } from '../../../Redux/Actions/VideoActions'
import { useSelector } from 'react-redux'

const TransCard = (props) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [state, setState] = useState({
        active: false,
        loader: false
    })

    const handleTransition = () => {
        dispatch(addTransition(props.name, selectedSlideIndex))
    }
    const handleRemove = (e) => {
        e.stopPropagation()
        dispatch(removeTransition(selectedSlideIndex))
    }

    const handleActive = () => {
        setState({
            ...state,
            loader: true,
            active: true
        })

    }

    const handleLeave = () => {
        setState({
            ...state,
            loader: false,
            active: false
        })
    }

    return (
        <div className="transition-single mt-2"
            onMouseEnter={() => handleActive()}
            onMouseLeave={() => handleLeave()}
            onClick={() => handleTransition()}
        >
            <img
                className="transition-img"
                src={state.active ? props.preview : props.thumbnail}
                onLoad={() => setState({
                    ...state,
                    loader: false
                })}
            />
            <div className="transition-txt text-light text-capitalize" style={{ fontSize: "15px" }}>{state.loader ? <i className="fa fa-spinner fa-spin" style={{ color: "#d10fd1", fontSize: 25 }} /> : props.name}</div>
            {
                props.name === selectedSlide.transitionName ?
                    <div className="slide-active" onClick={handleRemove}>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    : ''
            }
        </div>
    )
}

export default TransCard