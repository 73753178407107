import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import { BsStars } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { onCreateQuiz } from '../../../Redux/Actions/QuizActions';

const CreateModal = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [loader, setLoader] = useState({
        create: false
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let data = { name }
        dispatch(onCreateQuiz("create-quiz", data, navigate, loader, setLoader))
    }

    return (
        <Modal className="VideoModal small white" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec text-center p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Enter the Quiz Name</h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Quiz Name Here..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="inpBtn mt-3 create-modal-btn"><BsStars /> Next {loader.create ? <i className="fa fa-spinner fa-spin" /> : ""}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModal