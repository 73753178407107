import React from 'react'
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FaDesktop } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";

import { BiEditAlt } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

function ReviewQuiz() {
  return (
    <>
      <div className='quiz-card'>
         <h5 className='gradientTxt text-center pb-4'><span>Quiz Preview</span></h5>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className='text-center'>
                <Nav variant="v" className="tabStyleTwo">
                  <Nav.Item>
                    <Nav.Link eventKey="first"><MdPhoneIphone /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second"><FaDesktop /></Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            
              <Tab.Content className='pt-4'>
                <Tab.Pane eventKey="first">
                  <div className='quiz-prev-wrap'>
                    <div className='gradient-box'>
                      <div className='quiz-prev-in'>
                        <div className='quiz-prev-box'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span>Question 1/50</span>
                            <span>00:59</span>
                          </div>
                          <p className='pt-4'>Which planet in our solar system is known as the "Red Planet"?</p>
                        </div>

                        <div className='selected-ans pt-3'>
                          <div className='selected-ans-single active'>Venus</div>
                          <div className='selected-ans-single wrong'>Mars</div>
                          <div className='selected-ans-single'>Jupiter</div>
                          <div className='selected-ans-single'>Mercury</div>
                        </div>

                        <div className='row pt-5 pb-3'>
                          <div className='col-6'>
                            <button className='qz-button'>&lt;&lt; Prev</button>
                          </div>
                          <div className='col-6'>
                            <button className='qz-button blue'>Next &gt;&gt;</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className='quizWithNav'>

                    <div className='quiz-prev-wrap large'>
                      <div className='gradient-box'>
                        <div className='quiz-prev-in'>
                          <div className='quiz-prev-box'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <span>Question 1/50</span>
                              <span>00:59</span>
                            </div>
                            <div className='edit-area mt-3'>
                              <BiEditAlt />
                              <p>Which planet in our solar system is known as the "Red Planet"?</p>
                            </div>
                          </div>

                          <p style={{fontSize: '0.8rem'}} className='text-center mt-3'>Double Click Text to Edit</p>

                          <div className='selected-ans pt-3'>
                            <div className='selected-ans-single active'>Venus <MdDragIndicator /></div>
                            <div className='selected-ans-single wrong'>Mars <MdDragIndicator /></div>
                            <div className='selected-ans-single'>Jupiter <MdDragIndicator /></div>
                            <div className='selected-ans-single'>Mercury <MdDragIndicator /></div>
                          </div>

                          <div className='row justify-content-center pt-5 pb-3'>
                            <div className='col-4'>
                              <button className='qz-button'>&lt;&lt; Prev</button>
                            </div>
                            <div className='col-4'>
                              <button className='qz-button blue'>Next &gt;&gt;</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='quiz-prev-nav'><img src={require('../../images/quiz/nav-prev.svg').default} alt='' /></div>
                    <div className='quiz-prev-nav alt'><img src={require('../../images/quiz/nav-next.svg').default} alt='' /></div>

                  </div>
                </Tab.Pane>
              </Tab.Content>
          </Tab.Container>

      </div>
    </>
  )
}

export default ReviewQuiz