import React from 'react'
import Background from './Background'
import ImageLayer from './ImageLayer'
import TextLayer from './TextLayer'
import VideoLayer from './VideoLayer'
import { useDispatch } from "react-redux"
// import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../actions/chapterAction';
import Watermark from './Watermark'
import Logo from './Logo'
import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../Redux/Actions/VideoActions'

const PreviewMain = (props) => {
    const dispatch = useDispatch()

    const handleSelectLayer = (index) => {
        const layerIndex = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (index !== layerIndex) {
            dispatch(updateSelectedLayer(index, props.selectedSlideIndex))
        }
    }

    const handleResize = (width, height, x, y, layerIndex) => {
        console.log(width, height)

        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))
        console.log(width, height)

        dispatch(resizeLayer(width, height, x, y, props.selectedSlideIndex, layerIndex))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, props.selectedSlideIndex, layerIndex))
    }

    return (
        <>
            <Background
                background={props.selectedSlide.background}
                selectedSlideIndex={props.selectedSlideIndex}
                selectedSlide={props.selectedSlide}
                dimension={props.dimension}
            />
            <Logo />
            <Watermark />
            {props.selectedSlide.layers.map((layer, index) => {
                if (layer.type === "text") {
                    return (
                        <TextLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            handleRePosition={handleRePosition}
                            dimension={props.dimension}
                        />
                    )
                }
                if (layer.type === "image") {
                    return (
                        <ImageLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            dimension={props.dimension}
                            handleRePosition={handleRePosition}
                        />
                    )
                }
                if (layer.type === "video") {
                    return (
                        <VideoLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            dimension={props.dimension}
                            handleRePosition={handleRePosition}
                        />
                    )
                }
            })}
        </>
    )
}

export default PreviewMain