import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { onCreatePromoVideo } from '../../../Redux/Actions/VideoActions'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import { BsStars } from 'react-icons/bs'


const CreateModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [state, setState] = useState({
        name: "",
        sourceType: "course"
    })
    const [loader, setLoader] = useState({
        create: false
    })

    const handleChangeName = (e) => {
        setState({
            ...state,
            name: e.target.value
        })
    }

    const handleChangeType = (value) => {
        setState({
            ...state,
            sourceType: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let data = { ...state }
        dispatch(onCreatePromoVideo(data, navigate, loader, setLoader))
    }



    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec text-center p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Enter Promo Video Name</h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Promo Video Name Here..."
                            value={state.name}
                            onChange={handleChangeName}
                            required
                        />
                    </div>
                    <div className='row justify-content-center mt-4'>
                        <div className='col-lg-12'>
                            <div className='quiz-source-single mt-2 text-left'>
                                <div className='row align-items-center justify-content-between'>
                                    <div className='col'>
                                        <p>From Course Content</p>
                                    </div>
                                    <div className='col-auto'>
                                        <button type='button' className='btnBlue' onClick={() => handleChangeType("course")} style={state.sourceType === "course" ? {background : "green"} : {}}>{state.sourceType === "course" ? "Using" : "Use This"}</button>
                                    </div>
                                </div>
                            </div>
                           
                            <div className='quiz-source-single mt-2 text-left'>
                                <div className='row align-items-center justify-content-between'>
                                    <div className='col'>
                                        <p>Custom Input</p>
                                    </div>
                                    <div className='col-auto'>
                                        <button type='button' className='btnBlue' onClick={() => handleChangeType("custom")} style={state.sourceType === "custom" ? {background : "green"} : {}}>{state.sourceType === "custom" ? "Using" : "Use This"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="inpBtn mt-3 create-modal-btn"><BsStars /> Next {loader.create ? <i className="fa fa-spinner fa-spin" /> : ""}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModal