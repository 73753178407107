import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const YoutubeKeySearch = () => {
    const [key, setKey] = useState({
        text: '',
        loader: false
    });
    const [searchData, setSearchData] = useState([])

    const handleSubmit = () => {
        const temp = { keyword_suggestion: key.text }
        setKey({
            ...key,
            loader: true
        })
        axios({
            method: 'POST',
            url: 'https://app.rankreel.io/Keywordsget',
            data: temp,
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.data.status === true) {
                setSearchData(res.data.data)
            }
            setKey({
                ...key,
                loader: false
            })
        }).catch((error) => {
            console.log(error)
            setKey({
                ...key,
                loader: true
            })
        })
    }

    return (
        <div className="upgrade-content">
            <div className="row ">
                <div className="col-md-12 text-center">
                    <div className="project-input-box">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Projects"
                                onChange={(e) => setKey({
                                    ...key,
                                    text: e.target.value
                                })}
                            />
                            <button
                                className="yotube-btn"
                                onClick={handleSubmit}
                            >Submit</button>
                        </div>
                    </div>
                </div>

            </div>
            {key.loader ?
                <div className='text-center mt-5'>
                    < i className="fa fa-spinner fa-spin resize-loader" />
                </div>
                :
                searchData.length > 0 ?
                    <div className="row  d-flex justify-content-center  ">
                        <div className="col-md-12 mt-5">
                            <div className="upgrade-box-content text-white">
                                <table className="table table-dark table-striped" height="500px">
                                    <thead>
                                        <tr>
                                            <th scope="col">Keywords</th>
                                            <th scope="col">Monthly</th>
                                            <th scope="col">Search</th>
                                            <th scope="col">Suggested Bid ($)</th>
                                            <th scope="col">Competition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{curElem.kw}</th>

                                                    <td>{curElem.monthly !== undefined ?
                                                        curElem.monthly.length > 0 ?
                                                            curElem.monthly.map((month) => {
                                                                if (month !== null) {
                                                                    return (<>
                                                                        {month + ' '}
                                                                    </>
                                                                    )
                                                                }
                                                            }) : '' : ''}
                                                    </td>

                                                    <td>{curElem.n}</td>
                                                    <td>{curElem.sb}</td>
                                                    <td>{curElem.con}</td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : ''}
        </div>
    )
}

export default YoutubeKeySearch