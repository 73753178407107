import React from 'react'
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";

const GeneratePdf = ({ fileName, contentId, children }) => {

    const options = {
        filename: fileName,
        method: "save",
        
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it

        // might cause the page to crash or hang.
        // resolution: Resolution.EXTREME,
        // resolution: 1,
        page: {
            // margin is in MM, default is Margin.NONE = 0

            // margin: Margin.SMALL,
            // margin: Margin.SMALL,
            
            // default is 'A4'
            format: "letter",
            // default is 'portrait'
            orientation: "portrait"
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: "image/jpeg",
            qualityRatio: 0.5
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };

    // you can also use a function to return the target element besides using React refs
    const getTargetElement = () => document.getElementById(contentId);

    const downloadPdf = () => generatePDF(getTargetElement, options);

    return (
        <div onClick={downloadPdf}>
            {children}
        </div>
    )
}

export default GeneratePdf