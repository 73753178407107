import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// import { addTranscribeData } from '../../actions/courseAction';
import { ReactComponent as VideoIcon } from "../../../images/upload-complete.svg";
import { ReactComponent as AudioIcon } from "../../../images/using-audio.svg";
import { onFetchChapter, onTranscribeLanguage } from '../../../Redux/Actions/CourseActions';
import { fetchData } from '../../../Redux/Actions/CommonActions';

const ModalUpload = ({ show5, handleClose5, uploadData, chapterId }) => {

    const dispatch = useDispatch()
    const course = useSelector(state => state.course.data)
    const [loader, setLoader] = useState(false)
    const [languages, setLanguages] = useState([])
    const [dataVariable, setDataVariable] = useState({
        langCode: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDataVariable({
            ...dataVariable,
            [name]: value
        })
    }

    const fetchChapter = () => {
        handleClose5()
        let data = {
            chapterId: chapterId,
            courseId: course.id,
            type: uploadData.type
        }
        dispatch(onFetchChapter(data))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            url: uploadData.file,
            language_code: dataVariable.langCode,
            courseId: course.id,
            chapterId: chapterId,
        }
        setLoader(true)
        dispatch(onTranscribeLanguage(data, fetchChapter, loader, setLoader))
        // commonAxios("transcribe", data, dispatch, config)
        //     .then((res) => {
        //         if (res.status) {
        //             let obj = { ...res.data }
        //             if (obj.job_status === 1) {
        //                 fetchChapters()
        //                 handleClose5()
        //                 dispatch(addTranscribeData(res.data, chapterId, fileType))
        //             }
        //             dispatch(setAlert(res.msg, "success"))
        //         } else {
        //             dispatch(setAlert(res.msg, "danger"))
        //             handleClose5()
        //         }
        //         setLoader(false)
        //     }).catch(err => {
        //         setLoader(false)
        //         console.log(err)
        //     })
    }

    const fetchLanguages = () => {
        let data = {}
        dispatch(fetchData("transcribe-language", data, setLanguages))
    }
    useEffect(() => {
        fetchLanguages()
    }, [])

    return (
        <Modal className="VideoModal small" show={show5} centered>
            <Modal.Body className="bg-white text-center">
                <div className="modal-body bg-white text-center">
                    <div className="img-wrapper">
                        {uploadData.type === "audio" ?
                            <AudioIcon />
                            : <VideoIcon />}
                    </div>
                    <h4 className="modal-title mb-4" id="delete-warn" style={{ color: '#000000' }}>Upload
                        Complete</h4>
                    <span className="w-100 mx-auto mb-4 text-dark" >
                        We will alert you once the text version of your {uploadData.type ? "audio" : "video"} file is ready.
                    </span>
                    <form className="formSec" onSubmit={handleSubmit}>
                        <div className="inpField mt-3">
                            <select
                                className="inpField-inp"
                                name='langCode'
                                onChange={(e) => handleChange(e)}
                                required
                            >
                                <option value="">Select Language</option>
                                {languages.length > 0 ?
                                    languages.map((curElem, index) => {
                                        return (
                                            <option value={curElem.code} key={index}>{curElem.language}</option>
                                        )
                                    }) : ''}

                            </select>
                        </div>
                        <button type="submit" className="btn-block inpBtn mt-3" onClick={handleClose5}>Cancel</button>
                        <button type="submit" className="btn-block inpBtn mt-3">{loader ? <> Saving <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save"}</button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalUpload